import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EditableGrid } from "./EditableGrid";
import { setID } from "./helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { error, info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { getAllProducts } from "../Products/_api";
import { accessCheck, formatDate, removeEmptyFields } from "../../../ui/helpers/helpers";
import { createPlannedHarvestBulk } from "./_api";

const harvest = {
  id: 0,
  product_type: "",
  employees_amount: "",
  rating: "",
  product_qty: "",
  date: "",
  supervisor_id: "",
  farm_id: "",
  product_id: "",
  label_date: "",
  block_id: "",
  comment: "",
  apply_date: false,
  initial_status: "new",
};

export const PlannedHarvests = () => {
  const { request } = useFetch();
  const user = useSelector(({ auth: { user } }) => user);
  const history = useHistory();

  const [data, setData] = useState([harvest]);
  const [failedItems, setFailedItmes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    request(getAllProducts()).then((data) => data && setProducts(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewItem = () => {
    setData((oldData) => [...oldData, { ...harvest, id: setID(10000000, oldData) }]);
  };

  const handleRemoveItem = (id) => {
    setData((state) => state.filter((item) => (item || {}).id !== id));
  };

  const handleDataUpdate = (record = {}) => {
    const index = data.findIndex((item) => (item || {}).id === (record || {}).id);
    if (index === -1) return;
    setData((state) => [
      ...state.slice(0, index),
      { ...record, initial_status: "failed" },
      ...state.slice(index + 1),
    ]);
  };

  const transformData = (data = {}) => {
    return data.map(
      ({
        date,
        label_date,
        product_qty,
        employees_amount,
        apply_date,
        block_id,
        comment,
        farm_id,
        product_id,
        product_type,
        supervisor_id,
      }) => {
        const payload = {
          date: formatDate(date, "date"),
          label_date: label_date ? formatDate(label_date, "date") : null,
          product_qty: Number(product_qty),
          employees_amount: Number(employees_amount),
          apply_date,
          block_id,
          comment,
          farm_id,
          product_id,
          product_type,
          supervisor_id,
        };
        removeEmptyFields(payload);
        return payload;
      }
    );
  };

  const validateData = (data) => {
    return data
      .filter((item) => {
        const productMaxQty =
          (products.find((product) => String(product.id) === String(item.product_id)) || {})[
            item.product_type.toLowerCase() + "_type_maximum_limit"
          ] || 10000000;
        return (
          !item.product_type ||
          !item.date ||
          !item.product_id ||
          !item.farm_id ||
          !item.supervisor_id ||
          !item.product_qty ||
          item.employees_amount > 50 ||
          item.product_qty > productMaxQty
        );
      })
      .map((item) => (item || {}).id);
  };

  const handleSubmit = () => {
    const notValidatedItems = validateData(data);
    setFailedItmes(notValidatedItems);
    if (notValidatedItems.length) {
      data.forEach((item) => {
        if (notValidatedItems.includes(item.id)) {
          handleDataUpdate(item);
        }
      });
      error("Some of the items are missing required fields!");
    } else {
      const payload = transformData(data);
      if (!payload.length) {
        error("Data should have at least one harvest!");
        return;
      }
      setLoading(true);
      request(createPlannedHarvestBulk, payload, accessCheck(user))
        .then((data) => {
          if (!data) return;
          setData(() => [harvest]);
          setFailedItmes([]);
          info("Harvests have been submitted!");
          history.push("/planning/active-harvests");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Loader isOpen={loading} />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <EditableGrid
              data={data}
              handleNewItem={handleNewItem}
              handleRemoveItem={handleRemoveItem}
              setData={setData}
              failedItems={failedItems}
              products={products}
            />
            <div className="d-flex justify-content-end mt-10">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
                data-testid="submit"
                disabled={loading}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
