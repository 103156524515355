import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar } from "../../../ui/structures/SearchBar";
import { EditableGrid } from "../../../ui/components/EditableGrid";
import { getAllFarms, deleteFarm, createFarm, modifyFarm } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { setFarmsList } from "./_redux/actions";
import { SEARCH_DATA } from "./constants";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "20%",
    editable: true,
  },
  {
    title: "Abbreviation",
    dataIndex: "abbreviation",
    width: "55%",
    editable: true,
  },
];

const newDataScheme = {
  name: "",
  abbreviation: "",
};

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [farms, setFarms] = useState([]);

  const data = useSelector(({ farms }) => farms.farmsList) || [];

  useEffect(() => {
    request(getAllFarms())
      .then(
        (data) =>
          data &&
          data.map((farm) => ({
            ...farm,
            key: farm.id,
          }))
      )
      .then((data) => {
        dispatch(setFarmsList(data));
        setFarms(data);
      });
    // eslint-disable-next-line
  }, []);

  const KEY_MAP = {
    "BMG Farm": data.map((item) => item.name),
    Abbreviation: data.map((item) => item.abbreviation),
  };

  const handleSearch = (search) => {
    const _search = Object.entries(search).map((item) => [
      SEARCH_DATA.find((i) => i[1] === item[0])[0],
      item[1],
    ]);
    setFarms(
      data.filter((item) => {
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    );
  };

  const resetSearch = () => {
    setFarms(data);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Farm List</h3>
          </div>
          <div className="row">
            <div className="col-8">
              <SearchBar
                className="mb-5"
                onSearch={handleSearch}
                clearSearch={resetSearch}
                keyMap={KEY_MAP}
                placeholder="Search..."
              />
            </div>
          </div>
          <EditableGrid
            data={farms}
            setData={setFarms}
            listColumns={columns}
            newDataScheme={newDataScheme}
            deleteItem={deleteFarm}
            handleCreate={createFarm}
            handleUpdate={modifyFarm}
          />
        </div>
      </div>
    </div>
  );
}
