import get from "lodash/get";
import {
  timeDiffCalc,
  timeDiffCalcInMili,
  timeDiffDisplayInMili,
  setUtcDate,
} from "../../../ui/helpers/helpers";

export const modifyAttendnaces = (data) => {
  return data.map((attendance) => modifyAttendanceObject(attendance));
};

const getDistanceinHours = (startDate, endDate) => {
  const diffInSeconds = (new Date(endDate) - new Date(startDate)) / 1000;
  const hours = diffInSeconds / 3600;
  return +hours.toFixed(2);
};

export const modifyAttendanceObject = (data) => {
  return {
    ...data,
    firstname: get(data, "employee.firstname", ""),
    lastname: get(data, "employee.lastname", ""),
    nickname: get(data, "employee.nickname", ""),
    mobile: get(data, "employee.mobile", ""),
    supervisor: [
      get(data, "supervisor.firstname"),
      get(data, "supervisor.lastname"),
    ].join(" "),
    employee_fullname: [
      get(data, "employee.firstname"),
      get(data, "employee.lastname"),
    ].join(" "),
    user: [
      get(data, "user.firstname", ""),
      get(data, "user.lastname", ""),
    ].join(" "),
    farm: get(data, "farm.abbreviation", ""),
    start_date: data.start_time && data.start_time,
    start_time: data.start_time && setUtcDate(data.start_time),
    end_date: data.end_time && data.end_time,
    end_time: data.end_time && setUtcDate(data.end_time),
    duration: data.end_time && timeDiffCalc(data.end_time, data.start_time),
    duration_with_lunch:
      data.end_time &&
      (get(data, "has_lunch")
        ? timeDiffCalc(data.end_time, data.start_time, "lunch")
        : timeDiffCalc(data.end_time, data.start_time)),
    duration_in_mili:
      data.end_time && timeDiffCalcInMili(data.end_time, data.start_time),
    duration_with_lunch_in_mili:
      data.end_time &&
      (get(data, "has_lunch")
        ? timeDiffCalcInMili(data.end_time, data.start_time, "lunch")
        : timeDiffCalcInMili(data.end_time, data.start_time)),
    duration_total: data.end_time
      ? +getDistanceinHours(data.start_time, data.end_time)
      : "",
    lunch_break: get(data, "has_lunch") ? 30 : 0,
  };
};

function groupBy(a, keyFunction) {
  const groups = {};
  a.forEach(function(el) {
    const key = keyFunction(el);
    if (key in groups === false) {
      groups[key] = [];
    }
    groups[key].push(el);
  });
  return groups;
}

export const calculateSummaryAttendance = (attandences) => {
  const byEmployeeId = groupBy(attandences, (it) => it["employee_id"]);

  const output = Object.keys(byEmployeeId).map((employee_id) => {
    const getEmployeeInfo = byEmployeeId[employee_id][0];
    const duration_in_mili_sum = byEmployeeId[employee_id].reduce(
      (acc, it) => acc + it.duration_in_mili,
      0
    );
    const duration_with_lunch_in_mili_sum = byEmployeeId[employee_id].reduce(
      (acc, it) => acc + it.duration_with_lunch_in_mili,
      0
    );
    const lunch_break_sum = byEmployeeId[employee_id].reduce(
      (acc, it) => acc + it.lunch_break,
      0
    );
    return {
      id: employee_id,
      firstname: getEmployeeInfo.firstname,
      lastname: getEmployeeInfo.lastname,
      nickname: getEmployeeInfo.nickname,
      mobile: getEmployeeInfo.mobile,
      duration: getEmployeeInfo.duration,
      lunch_break: lunch_break_sum,
      duration_in_mili: timeDiffDisplayInMili(duration_in_mili_sum),
      duration_with_lunch_in_mili: timeDiffDisplayInMili(
        duration_with_lunch_in_mili_sum
      ),
      duration_total: getEmployeeInfo.duration_total,
    };
  });
  return output;
};
