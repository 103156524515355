import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import DataGrid from "../../../ui/components/DataGrid";
import { renderHarvestsRow, formatDate, setSelectOptions } from "../../../ui/helpers/helpers";
import { getCurrenttHarvests } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { setHarvestsList } from "./_redux/actions";
import { modifyHarvests } from "../HarvestsReports/helpers";
import { getAllFarms } from "../Farms/_api";
import { getAllProducts } from "../Products/_api";
import { getAllUsers } from "../UserManagment/_api";
import { FilterForm } from "../../../ui/structures/FilterForm";
import { productType, statusType } from "../PlannedHarvests/constants";
import { Loader } from "../../../ui/components/Loader";

const HEADINGS = [
  ["status", "Status"],
  ["id", "ID"],
  ["date", "Date"],
  ["farm", "Farm"],
  ["label_date", "Label Date"],
  ["start_time", "Start"],
  ["end_time", "Finish"],
  ["duration", "Duration"],
  ["employees_amount", "People"],
  ["product", "Product"],
  ["product_type", "Format"],
  ["product_qty", "Qty"],
];

const filterResetValues = {
  products: [],
  farms: [],
  types: [],
  supervisors: [],
};

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [farms, setFarms] = useState([]);
  const [products, setProducts] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [resetKey, setResetKey] = useState(false);

  const data = useSelector(({ harvests }) => harvests.harvestsList) || [];

  const getHarvests = (values, actions) => {
    setLoading(true);
    request(() =>
      values
        ? getCurrenttHarvests(
            formatDate(new Date(), "date"),
            formatDate(new Date(), "date"),
            values.products,
            values.farms,
            values.types,
            values.supervisors
          )
        : getCurrenttHarvests(formatDate(new Date(), "date"), formatDate(new Date(), "date"))
    )
      .then((data) => data && modifyHarvests(data))
      .then((data) => {
        dispatch(setHarvestsList(data));
        setLoading(false);
      })
      .finally(() => actions && actions.setSubmitting(false));
  };

  useEffect(() => {
    getHarvests();
    Promise.all([request(getAllFarms()), request(getAllProducts()), request(getAllUsers())]).then(
      ([farms, products, users]) => {
        if (!farms || !products || !users) return;
        setFarms(farms.filter(({ name }) => name));
        setProducts(products.filter(({ name }) => name));
        setSupervisors(() =>
          users
            .filter(({ firstname, lastname }) => firstname || lastname)
            .map(({ firstname, lastname, ...user }) => ({
              ...user,
              name: [firstname, lastname].join(" "),
            }))
        );
      }
    );
    // eslint-disable-next-line
  }, []);

  const sortHelper = function(a, b) {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  };

  const farmsOptions = useMemo(() => farms.map(setSelectOptions).sort(sortHelper), [farms]);

  const supervisorOptions = useMemo(() => supervisors.map(setSelectOptions).sort(sortHelper), [
    supervisors,
  ]);

  const productsOptions = useMemo(() => products.map(setSelectOptions).sort(sortHelper), [
    products,
  ]);

  const onReset = (handleReset) => () => {
    const filterPayload = { ...filterResetValues, from: formatDate(new Date(), "date") };
    handleReset({ values: filterPayload });
    getHarvests(filterPayload);
    setResetKey(new Date(Date.now()));
  };

  return (
    <>
      <Loader isOpen={loading} title="Loading harvests..." />
      <div className="row justify-content-center">
        <div className="col-12">
          <div>
            <FilterForm
              onSubmit={getHarvests}
              onReset={onReset}
              products={productsOptions}
              farms={farmsOptions}
              types={productType.sort(sortHelper)}
              supervisors={supervisorOptions}
              status={statusType}
              key={resetKey}
              title="Filter Harvests"
              endDateEnabled={false}
              startDateEnabled={false}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between mb-3">
              <h3 className="text-dark mb-5">Harvest List</h3>
              <div>
                <IconButton variant="contained" color="secondary" className="ml-8">
                  <InputIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
            <div className="mb-5">
              <Link
                to={{
                  pathname: `/add-harvest`,
                  newUser: true,
                }}
                data-testid="add-harvest"
              >
                <button className="px-7 mr-5 btn btn-primary">New Harvest</button>
              </Link>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderRow={renderHarvestsRow}
              editable
              loading={loading}
              link="harvests"
            />
          </div>
        </div>
      </div>
    </>
  );
}
