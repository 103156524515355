import React from "react";
import { Button } from "@material-ui/core";

export const FormActions = ({ handleClose, handleSubmit, isSubmitting, className }) => {
  return (
    <div className={`d-flex justify-content-end align-items-center mr-10 pb-10 ${className}`}>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        className="px-9 mr-3"
        data-testid="cancel"
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        onClick={handleSubmit}
        disabled={isSubmitting}
        variant="contained"
        color="secondary"
        size="large"
        className="px-9"
        data-testid="submit"
      >
        Save
      </Button>
    </div>
  );
};
