export const ITEM_TYPES = {
  link: "link",
  heading: "heading",
  menu: "menu",
};
export const ADMIN_MENU = [
  {
    type: ITEM_TYPES.link,
    iconUrl: "/assets/icons/dashboard.svg",
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.menu,
    title: "Administration (Settings)",
    iconUrl: "/assets/icons/administration.svg",
    to: "/administration",
    items: [
      {
        type: "link",
        title: "Farms List",
        to: "/administration/farms-list",
      },
      {
        type: "link",
        title: "Products List",
        to: "/administration/products-list",
      },
      {
        type: "link",
        title: "User Management",
        to: "/administration/user-management",
      },
      {
        type: "link",
        title: "Employee Management",
        to: "/administration/employee-management",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Operations",
    iconUrl: "/assets/icons/administration.svg",
    to: "/operations",
    items: [
      {
        type: "link",
        title: "Current Harvests",
        to: "/operations/current-harvests",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Planning",
    iconUrl: "/assets/icons/manager.svg",
    to: "/planning",
    items: [
      {
        type: "link",
        title: "Active Harvests",
        to: "/planning/active-harvests",
      },
      {
        type: "link",
        title: "Planned Harvests",
        to: "/planning/planned-harvests",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Reports",
    iconUrl: "/assets/icons/administration.svg",
    to: "/reports",
    items: [
      {
        type: "link",
        title: "Harvests Reports",
        to: "/reports/total-reports",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Attendance",
    iconUrl: "/assets/icons/harvest.svg",
    to: "/attendance",
    items: [
      {
        type: "link",
        title: "Attendance Reports",
        to: "/attendance/attendance-reports",
      },
      {
        type: "link",
        title: "Team Attendance",
        to: "/attendance/attendance-list",
      },
    ],
  },
];

export const MANAGER_MENU = [
  {
    type: ITEM_TYPES.link,
    iconUrl: "/assets/icons/dashboard.svg",
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.menu,
    title: "Operations",
    iconUrl: "/assets/icons/administration.svg",
    to: "/operations",
    items: [
      {
        type: "link",
        title: "Current Harvests",
        to: "/operations/current-harvests",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Planning",
    iconUrl: "/assets/icons/manager.svg",
    to: "/planning",
    items: [
      {
        type: "link",
        title: "Active Harvests",
        to: "/planning/active-harvests",
      },
      {
        type: "link",
        title: "Planned Harvests",
        to: "/planning/planned-harvests",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Reports",
    iconUrl: "/assets/icons/administration.svg",
    to: "/reports",
    items: [
      {
        type: "link",
        title: "Harvests Reports",
        to: "/reports/total-reports",
      },
    ],
  },
];

export const SUPERVISOR_MENU = [
  {
    type: ITEM_TYPES.link,
    iconUrl: "/assets/icons/dashboard.svg",
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.menu,
    title: "Administration (Settings)",
    iconUrl: "/assets/icons/administration.svg",
    to: "/administration",
    items: [
      {
        type: "link",
        title: "Farms List",
        to: "/administration/farms-list",
      },
      {
        type: "link",
        title: "Products List",
        to: "/administration/products-list",
      },
      {
        type: "link",
        title: "User Management",
        to: "/administration/user-management",
      },
      {
        type: "link",
        title: "Employee Management",
        to: "/administration/employee-management",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Attendance",
    iconUrl: "/assets/icons/harvest.svg",
    to: "/attendance",
    items: [
      {
        type: "link",
        title: "Team Attendance",
        to: "/attendance/attendance-list",
      },
    ],
  },
];
