import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHtmlClassService } from "../../../../_metronic/layout/_core/MetronicLayout";
import { useFetch } from "../../../../hooks/fetch.hook";
import { MyGreetings } from "./MyGreetings";
import { getCurrentUser } from "../_api";

import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  TOTPSetup,
} from "aws-amplify-react";
import { Auth } from "@aws-amplify/auth";
import { I18n } from "@aws-amplify/core";
import "./TotpQrCodeFix.css";

import config from "react-global-configuration";
import { AlertBar } from "../../../../ui/components/AlertBar";

const dict = {
  en: {
    "Forget your password? ": " ", // Blank out hint in default theme
    "Confirm TOTP Code": "2FA Login Code",
  },
};

I18n.setLanguage("en");
I18n.putVocabularies(dict);

const errorMap = (message) => {
  if (/^Custom auth.*/i.test(message)) {
    return "Incorrect username or password.";
  }

  return message;
};

const MyTheme = {
  toast: {
    backgroundColor: "#e8cccc",
    color: "#8b0000",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    border: "1px solid",
    borderRadius: ".42rem",
    borderColor: "#e8cccc",
  },
  sectionFooterSecondaryContent: {
    display: "none",
  },
  sectionFooter: {
    textAlign: "center",
  },
  sectionBody: {
    textAlign: "center",
  },
  sectionHeader: {
    fontWeight: "500",
    textAlign: "center",
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    color: "black",
    textTransform: "uppercase",
  },
  container: {
    paddingTop: "1rem",
    //'paddingBottom': '1rem',
  },
  formField: {
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  hint: {
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    color: "black",
  },
  input: {
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    borderRadius: ".42rem",
    border: "1px solid",
    borderColor: "#f3f6f9",
    backgroundColor: "#f3f6f9",
  },
  inputLabel: { display: "none" },
  button: {
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    borderRadius: ".42rem",
    border: "1px solid",
    borderColor: "#3699FF",
    backgroundColor: "#3699FF",
    color: "#FFFFFF",
    width: "100%",
  },
};

function Login(props) {
  const [status, setStatus] = useState("");
  const [expiredMessage, setExpiredMessage] = useState(false);

  const uiService = useHtmlClassService();

  useEffect(() => {
    setExpiredMessage(Boolean(localStorage.getItem("sessionExpired")));
  }, []);

  const { request } = useFetch();

  function handleAuthStateChange(state) {
    setStatus(state);
    console.log(state);
    if (state === "signedIn") {
      Auth.currentUserInfo().then((userInfo) => {
        if (userInfo && userInfo.attributes) {
          // Require check as this bounces on logout
          request(getCurrentUser).then((userData) => {
            if (userData) {
              const {
                attributes: { email },
              } = userInfo;

              const {
                id,
                firstname,
                is_superuser,
                lastname,
                mobile,
                role,
              } = userData;

              // console.log(userInfo);
              // console.log(userData);

              // UserInfo = {
              //   attributes: "email, email_verified, name, sub",
              //   id,
              //   username,
              // };

              // userData = {
              //   cognito_user: "email, email_verified, name, sub, username",
              //   id,
              //   username,
              //   name,
              //   sub,
              //   user: "created_at, external_id, id, last_modified, name",
              //   user_info: "email, email_verified, principalId",
              // };

              const user = {
                id: id,
                username: firstname,
                email,
                firstname,
                lastname,
                role,
                is_superuser,
                mobile,
                sessionStart: new Date(),
                // Administrator
                // occupation: title,
                // companyName: "Stub Company",
                // phone: phone,
                //password: "demo",
                // accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
                //refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
                //pic: toAbsoluteUrl("/media/users/300_25.jpg"),
                // address: {
                //   addressLine: "L-12-20 Vertex, Cybersquare",
                //   city: "San Francisco",
                //   state: "California",
                //   postCode: "45000"
                // },
                // socialNetworks: {
                //   linkedIn: "https://linkedin.com/admin",
                //   facebook: "https://facebook.com/admin",
                //   twitter: "https://twitter.com/admin",
                //   instagram: "https://instagram.com/admin"
                // }
              };
              localStorage.setItem("sessionExpired", "");
              props.fulfillUser(user);
            }
          });
        }
      });
      // Auth.configure({ storage: localStorage });
    }
  }

  return (
    <>
      <div className="container">
      {expiredMessage && (
        <AlertBar
          message="Your session has expired. Please, reauthenticate."
          severity="warning"
        />
      )}
        <div className="d-flex justify-content-center py-10 rounded">
          <div className="col col-md-6 col-lg-4 login-form login-signin">
            {status === "signIn" && (
              <div className="d-flex align-items-center justify-content-center mb-5 mb-lg-25">
                <img
                  className="img-fluid w-100"
                  src={toAbsoluteUrl(uiService.getLogo())}
                  alt="Logo"
                  style={{
                    backgroundColor: "#1E1E2D",
                    borderRadius: ".42rem",
                  }}
                />
              </div>
            )}
            <div>
              {/*begin::Form*/}
              <Authenticator
                theme={MyTheme}
                onStateChange={handleAuthStateChange}
                amplifyConfig={config.get("Auth")}
                errorMessage={errorMap}
                hideDefault={true}
              >
                <SignIn />
                <ConfirmSignUp />
                <ConfirmSignIn />
                <ForgotPassword />
                <RequireNewPassword />
                <TOTPSetup />
                <MyGreetings props={props} />
              </Authenticator>
              {/*end::Form*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
