import { harvestTypes } from "./actions";

const initialState = {
  harvestsList: [],
};

export function harvestsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case harvestTypes.SET_DATA:
      return {
        ...state,
        harvestsList: payload,
      };

    default:
      return state;
  }
}
