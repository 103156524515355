import { reportsTypes } from "./actions";

const initialState = {
  reportsList: [],
  reportsSummeryList: [],
  filterOptions: {
    from: new Date(Date.now()),
    to: new Date(Date.now()),
    supervisors: [],
    employees: [],
    farms: [],
  },
};

export function attendanceReportsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case reportsTypes.SET_DATA:
      return {
        ...state,
        reportsList: payload,
      };

    case reportsTypes.SET_SUMMERY_DATA:
      return {
        ...state,
        reportsSummeryList: payload,
      };

    case reportsTypes.UPDATE_DATA:
      const index = state.reportsList.findIndex(
        (item) => item.id === payload.id
      );
      const myState = [...state.reportsList];
      myState.splice(index, 1, payload);

      return {
        ...state,
        reportsList: [...myState],
      };

    case reportsTypes.SET_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: payload,
      };

    case reportsTypes.RESET_FILTER:
      return {
        ...state,
        filterOptions: initialState.filterOptions,
      };

    default:
      return state;
  }
}
