import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedDatePicker } from "../../components/DatePicker";
import { formatDate } from "../../helpers/helpers";

export const StartDate = () => {
  const { values } = useFormikContext();

  const [minDate, setMinDate] = useState(null);

  useEffect(() => {
    const threeMonthInMs = 7889400000;
    let endDate = values.to;
    if (!values.to) endDate = new Date(Date.now());
    setMinDate(new Date(Date.parse(endDate) - threeMonthInMs));
    // eslint-disable-next-line
  }, [values.to]);

  return (
    <div
      style={{
        flex: "0 0 100px",
        maxWidth: 100,
        margin: "0 7px 0 12px",
      }}
    >
      <MappedDatePicker
        name="from"
        className="border border-secnodary"
        placeholderText={formatDate(Date.now(), "date", "dd/MM/yyyy")}
        minDate={minDate}
      />
    </div>
  );
};
