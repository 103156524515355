import React from "react";
import { Formik } from "formik";
import { MappedSelect } from "../../../ui/components/Select";
import { MappedSwitch } from "../../../ui/components/Switch";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { FormActions } from "../../../ui/structures/FormActions";
import { MappedTextArea } from "../../../ui/components/TextArea";
import { AttendanceFormSchema } from "../../../helpers/schema";

export const AttendanceForm = ({
  data,
  handleClose,
  handleSubmit,
  employees,
  farms,
}) => {
  return (
    <Formik
      initialValues={{ ...data, note: data.note ? data.note : "" }}
      onSubmit={handleSubmit}
      validationSchema={AttendanceFormSchema}
    >
      {({ isSubmitting, handleSubmit, values }) => (
        <>
          <div className="bg-white rounded mb-10">
            <div className="px-15 py-10">
              <h6 className="mb-10">Attendance Details</h6>

              <div className="row mb-3">
                <div className="col-3">
                  <p className="text-right text-muted">Employee</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedSelect name="employee_id" options={employees} />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <p className="text-right text-muted">Farm</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedSelect name="farm_id" options={farms} />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-3">
                  <p className="text-right text-muted">Lunch</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedSwitch name="has_lunch" checked={values.has_lunch} />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-3 ">
                  <p className="text-right text-muted">Start Time</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedDatePicker
                    name="start_time"
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    timeIntervals={10}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-3 ">
                  <p className="text-right text-muted">End Time</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedDatePicker
                    name="end_time"
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    timeIntervals={10}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-3 ">
                  <p className="text-right text-muted">Note</p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedTextArea
                    name="note"
                    multiline="true"
                    rows={6}
                    data-testid="note"
                  />
                </div>
              </div>

              <hr className="mt-15 mb-10" />

              <FormActions
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
              />
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
