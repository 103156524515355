import React, { useState } from "react";
import { Table, Input, InputNumber, Form } from "antd";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { useFetch } from "../../../hooks/fetch.hook";
import { Select } from "../Select";
import { accessCheck } from "../../../ui/helpers/helpers";
import "antd/dist/antd.css";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps} style={{ border: "none" }}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EditableGrid = ({
  data = [],
  setData,
  hideSelect,
  listColumns,
  newDataScheme,
  deleteItem,
  handleCreate,
  handleUpdate,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user);

  const dataKeys = () => {
    return data.reduce((acc, item) => {
      acc[item.key] = true;
      return Object.keys(acc);
    }, {});
  };

  const getRandomNumber = (max) => Math.floor(Math.random() * Math.floor(max));

  const setKey = (max) => {
    let random = getRandomNumber(max);
    const keysArr = dataKeys();
    if (!keysArr.find((item) => item === random)) {
      return random;
    } else setKey(max);
  };

  const handleChangePerPage = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const handleChangePage = (_, page) => {
    setPage(page);
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...newDataScheme,
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async ({ key, id, newFarm }) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      request(newFarm ? handleCreate : handleUpdate, row, id).then((data) => {
        if (data) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, newFarm: false, ...row, ...data });
          setData(newData);
          setEditingKey("");
        }
      });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = ({ key, id }) => {
    setEditingKey("");
    if (!id) {
      setData(data.filter((item) => item.key !== key));
    } else
      request(deleteItem, id).then((res) => {
        if (res) {
          setData(data.filter((item) => item.key !== key));
          setEditingKey("");
        }
      });
  };

  const handleAdd = () => {
    const newData = {
      ...newDataScheme,
      key: setKey(10000000),
      newFarm: true,
    };
    setData([...data, newData]);
    edit(newData);
  };
  const columns = [
    ...listColumns,
    {
      title: "Edit",
      dataIndex: "edit",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => save(record)}
              disabled={!accessCheck(user)}
              style={{
                marginRight: 8,
              }}
              data-testid="save"
            >
              Save
            </Button>
            <Button onClick={cancel} data-testid="cancel">
              <span>Cancel</span>
            </Button>
          </span>
        ) : (
          <Button
            variant="contained"
            className="bg-light"
            disabled={!accessCheck(user) || editingKey !== ""}
            onClick={() => edit(record)}
            data-testid="edit"
          >
            <EditIcon color="secondary" />
          </Button>
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      render: (text, record) =>
        data.length >= 1 ? (
          <Button
            variant="contained"
            className="bg-light"
            onClick={() => handleDelete(record)}
            disabled={!accessCheck(user)}
            data-testid="delete"
          >
            <DeleteIcon color="secondary" />
          </Button>
        ) : null,
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
      {accessCheck(user) && (
        <div className="text-right mt-5" style={{ position: "absolute", right: "9%" }}>
          <Button
            variant="contained"
            className="bg-light"
            onClick={handleAdd}
            disabled={Boolean(editingKey)}
            data-testid="add"
          >
            <AddCircleOutlineIcon color="secondary" />
          </Button>
        </div>
      )}
      <div
        className={`container mt-15 p-5 bg-white border-top d-flex 
            align-items-center
            justify-content-${hideSelect ? "center" : "between"}`}
      >
        {Math.ceil(data.length / perPage) > 1 ? (
          <Pagination
            count={Math.ceil(data.length / perPage)}
            page={page}
            showFirstButton
            showLastButton
            className="text-primary"
            color="secondary"
            classes={{ root: "text-primary" }}
            shape="rounded"
            onChange={handleChangePage}
          />
        ) : (
          <div />
        )}

        {hideSelect ? null : (
          <div className="d-flex px-8 justify-content-center align-items-center">
            <div>
              <Select
                className="px-2 py-1 h-auto"
                value={perPage}
                options={[
                  { value: 5, label: 5 },
                  { value: 10, label: 10 },
                  { value: 20, label: 20 },
                ]}
                IconComponent={ExpandMoreIcon}
                onChange={handleChangePerPage}
              />
            </div>
            <div className="text-muted pl-2">
              Showing {(page - 1) * perPage + 1}-
              {data.length > perPage ? page * perPage : data.length} of {data.length}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
