import React, { useEffect, useState, useMemo } from "react";
import get from "lodash/get";
import DataGrid from "../../../ui/components/DataGrid";
import {
  renderRow,
  insert,
  formatDate,
  setUtcDate,
} from "../../../ui/helpers/helpers";
import {
  modifyMyAttendance,
  createMyAttendance,
  getTodayEmployees,
} from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { AddAttendance } from "./AddAttendance";
import { AddFarm } from "./AddFarm";
import { AlertBar } from "../../../ui/components/AlertBar";

const HEADINGS = [
  ["firstname", "First Name"],
  ["lastname", "Last Name"],
  ["nickname", "Nickname"],
  ["mobile", "Mobile"],
];

export function List() {
  const { request } = useFetch();

  const [attendances, setAttendances] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedFarm, setSelectedFarm] = useState({});

  const recordValue = (startTime, endTime) => {
    if (startTime && !endTime) {
      return true;
    }
    return false;
  };

  const renewData = (res, item, data, setData, isLunchToggle) => {
    if (!res || !item) return;
    const index = data.findIndex((item) => item.id === res.employee_id);
    index !== -1 &&
      setData((state) =>
        insert(state, index, {
          ...item,
          ...res,
          start_time: setUtcDate(res.start_time),
          end_time: setUtcDate(res.end_time),
          id: item.id,
          record: isLunchToggle ? item.record : !item.record,
          attendance_id: res.id,
        })
      );
  };

  const createAttendance = (item, data, setData) => {
    request(createMyAttendance, {
      start_time: formatDate(Date.now()),
      date: formatDate(Date.now(), "date"),
      employee_id: item.id,
      end_time: null,
      has_lunch: false,
      farm_id: get(selectedFarm, "id"),
    }).then((res) => renewData(res, item, data, setData));
  };

  const modifyAttendance = (item, data, setData, isLunchToggle) => {
    request(
      modifyMyAttendance,
      {
        date: item.date,
        start_time: item.start_time,
        end_time: isLunchToggle ? item.end_time : formatDate(Date.now()),
        employee_id: item.id,
        has_lunch: isLunchToggle ? !item.has_lunch : item.has_lunch,
        farm_id: item.farm_id,
      },
      item.attendance_id
    ).then((res) => renewData(res, item, data, setData, isLunchToggle));
  };

  const handleSwitch = (item, data, setData, isLunchToggle) => {
    if (isLunchToggle) modifyAttendance(item, data, setData, isLunchToggle);
    else
      item.record
        ? modifyAttendance(item, data, setData)
        : createAttendance(item, data, setData);
  };

  useEffect(() => {
    request(getTodayEmployees).then((data) => {
      if (!data) return;
      setAttendances(
        data.map(({ last_attendance, ...item }) => ({
          ...item,
          start_time: setUtcDate(get(last_attendance, "start_time")),
          end_time: setUtcDate(get(last_attendance, "end_time")),
          date: get(last_attendance, "date"),
          attendance_id: get(last_attendance, "id"),
          farm_id: get(last_attendance, "farm_id"),
          has_lunch: get(last_attendance, "has_lunch"),
          record:
            last_attendance &&
            recordValue(last_attendance.start_time, last_attendance.end_time),
        }))
      );
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const switchDisabled = useMemo(
    () => Object.keys(selectedFarm || {}).length === 0,
    [selectedFarm]
  );

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div
          className="position-absolute w-100 pr-7"
          style={{ bottom: "100%" }}
        >
          {!Object.keys(selectedFarm || {}).length && (
            <AlertBar
              severity="warning"
              message="Select a farm in order to start attendance"
            />
          )}
        </div>
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Team Attendance</h3>
          </div>
          <DataGrid
            data={attendances}
            setData={setAttendances}
            headings={HEADINGS}
            renderRow={renderRow}
            switchable
            handleSwitch={handleSwitch}
            selected={selected}
            setSelected={setSelected}
            loading={loading}
            switchDisabled={switchDisabled}
          />
          <div className="d-flex justify-content-between">
            <AddAttendance
              setData={setAttendances}
              data={attendances}
              farm={selectedFarm}
              disabled={switchDisabled}
            />
            <AddFarm
              farm={selectedFarm}
              setFarm={setSelectedFarm}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
