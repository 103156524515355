import config from "react-global-configuration";
import { error } from "../../../helpers/toasts";

export const downloadFile = (
  fileName,
  getUrl = getDownloadFileUrl,
  setLoading,
  ...props
) => {
  return getUrl(fileName, props)
    .then((data) => data.json())
    .then((data) => startDownload(data.url))
    .catch(err => error((err || {}).message))
    .finally(() => setLoading && setLoading(false))
};

const getDownloadFileUrl = (fileName) =>
  fetch(`${config.get("ApiUrl").Rest}/files`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ file_name: fileName }),
  });

const startDownload = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noopener noreferrer");
  link.click();
};
