import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { SearchBar } from "../../../ui/structures/SearchBar";
import DataGrid from "../../../ui/components/DataGrid";
import { renderProductsRow, accessCheck } from "../../../ui/helpers/helpers";
import { getAllProducts, deleteProduct } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { setProductsList, deleteProductData } from "./_redux/actions";
import { SEARCH_DATA } from "./constants";

const HEADINGS = [
  ["name", "Product"],
  ["bunch_type_allowed", "Bunch"],
  ["loose_type_allowed", "Loose"],
  ["bundles_type_allowed", "Bundles"],
  ["loose_kg_type_allowed", "Loose (kg)"],
  ["crates_type_allowed", "Crates"],
  ["bin_type_allowed", "Bin"],
];

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const data = useSelector(({ product }) => product.productsList) || [];
  const user = useSelector(({ auth: { user } }) => user);

  const sortHelper = function(a, b) {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  };

  useEffect(() => {
    request(getAllProducts())
      .then((data) => {
        if (!data) return;
        return data.sort(sortHelper);
      })
      .then((data) => {
        dispatch(setProductsList(data));
        setProducts(data);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const KEY_MAP = {
    Product: data.map((item) => item.name),
  };

  const handleSearch = (search) => {
    const data = products;
    dispatch(
      setProductsList(
        data.filter((item) => {
          const _search = Object.entries(search).map((item) => [
            SEARCH_DATA.find((i) => i[1] === item[0])[0],
            item[1],
          ]);
          return (
            _search.length === 0 ||
            _search.every(([k, v]) => {
              if (Array.isArray(item[k])) {
                return item[k].includes(v);
              }
              return item[k] === v;
            })
          );
        })
      )
    );
  };

  const resetSearch = () => {
    dispatch(setProductsList(products));
  };

  const handleDeleteProduct = (id) => {
    request(deleteProduct, id).then(() => dispatch(deleteProductData(id)));
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Products</h3>
            <div>
              <IconButton variant="contained" color="secondary" className="ml-8">
                <InputIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
          <div className="mb-5"></div>
          <div className="row">
            <div className="col-8">
              <SearchBar
                className="mb-5"
                onSearch={handleSearch}
                clearSearch={resetSearch}
                keyMap={KEY_MAP}
                placeholder="Search products..."
              />
            </div>
          </div>
          <DataGrid
            data={data}
            headings={HEADINGS}
            loading={loading}
            renderRow={renderProductsRow}
            editable
            deletable
            addRow="/add-product"
            link="products"
            handleDelete={handleDeleteProduct}
            hasAccess={accessCheck(user)}
          />
        </div>
      </div>
    </div>
  );
}
