import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import Skeleton from "@material-ui/lab/Skeleton";
import { createHarvest, modifyHarvest, getHarvestById } from "./_api";
import { getAllFarms } from "../Farms/_api";
import { getAllProducts } from "../Products/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { formatDate, removeEmpty, accessCheck, setSelectOptions, setUtcDate, sortHelper } from "../../../ui/helpers/helpers";
import { getCurrentUser } from "../Auth/_api";
import { getHarvest } from "./_redux/selectors";
import { getActiveHarvest } from "../ActiveHarvests/_redux/selectors";
import { HarvestForm } from "./HarvestForm";

export function Card({ history, match }) {
  const { request } = useFetch();

  const [cardData, setCardData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const harvestId = match.params.id;

  const isActiveHarvest = history.location.isActiveHarvest;

  const data =
    useSelector(isActiveHarvest ? getActiveHarvest(harvestId) : getHarvest(harvestId)) || {};
  const user = useSelector(({ auth: { user } }) => user);

  const [farms, setFarms] = useState([]);
  const [products, setProducts] = useState([]);
  const [supervisorId, setSupervisordId] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const transofrmHarvest = ({date, label_date, ...data}) => {
    return {
      ...data,
      date: date && new Date(date),
      label_date: label_date && new Date(label_date),
      start_time: data && setUtcDate(data.start_time),
      end_time: data && setUtcDate(data.end_time),
    }
  }

  useEffect(() => {
    if (!Object.keys(data).length && harvestId) {
      request(getHarvestById, harvestId, accessCheck(user)).then(
        (data) => data && setCardData(transofrmHarvest(data))
      );
    } else setCardData(data);
    Promise.all([
      request(getAllFarms()),
      request(getAllProducts()),
      request(getCurrentUser),
    ]).then(([farms, products, user]) => {
      if (!farms || !products || !user) return;
      setFarms(farms);
      setProducts(products);
      setSupervisordId(get(user, "id"));
    });
    // eslint-disable-next-line
  }, []);

  const handleClose = () => history.goBack();

  const handleModalOpen = () => setModalOpen(true);

  const handleAddHarvest = (values, setSubmitting) => {
    request(createHarvest, {
      ...values,
      supervisor_id: supervisorId,
      date: formatDate(values.date, "date"),
      start_time: startTime,
      end_time: endTime,
    })
      .then((data) => data && history.push(`/operations/current-harvests`))
      .finally(() => setSubmitting(false));
  };

  const handleEdtiHarvest = (values, setSubmitting) => {
    request(
      modifyHarvest,
      {
        ...values,
        supervisor_id: get(cardData, "supervisor_id"),
        date: formatDate(values.date, "date"),
        start_time: startTime,
        end_time: endTime,
      },
      harvestId,
      accessCheck(user)
    )
      .then((data) => data && history.push(`/operations/current-harvests`))
      .finally(() => setSubmitting(false));
  };

  const handleSubmit = (values, { setSubmitting }) => {
    removeEmpty(values);
    harvestId ? handleEdtiHarvest(values, setSubmitting) : handleAddHarvest(values, setSubmitting);
  };

  const farmsOptions = useMemo(() => farms.map(setSelectOptions).sort(sortHelper), [farms]);

  const productsOptions = useMemo(() => products.map(setSelectOptions).sort(sortHelper), [
    products,
  ]);

  if (harvestId && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <HarvestForm
      data={cardData}
      handleSubmit={handleSubmit}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      startTime={startTime}
      endTime={endTime}
      harvestId={harvestId}
      user={user}
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      handleModalOpen={handleModalOpen}
      productsOptions={productsOptions}
      farms={farmsOptions}
      handleClose={handleClose}
      products={products}
    />
  );
}
