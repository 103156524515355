import { farmTypes } from "./actions";

const initialState = {
  farmsList: [],
};

export function farmsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case farmTypes.SET_DATA:
      return {
        ...state,
        farmsList: payload,
      };

    case farmTypes.DELETE_FARM:
      return {
        ...state,
        farmsList: [...state.farmsList.filter(({ id }) => id !== payload)],
      };

    default:
      return state;
  }
}
