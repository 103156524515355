import { reportsTypes } from "./actions";

const initialState = {
  reportsList: [],
  filterOptions: {
    from: new Date(Date.now()),
    to: new Date(Date.now()),
    products: [],
    farms: [],
    types: [],
    supervisors: []
  },
};


export function reportsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case reportsTypes.SET_DATA:
      return {
        ...state,
        reportsList: payload,
      };

      case reportsTypes.UPDATE_DATA:
        const index = state.reportsList.findIndex((item) => item.id === payload.id);
        const myState = [...state.reportsList];
        myState.splice(index, 1, payload);
  
        return {
          ...state,
          reportsList: [...myState],
        };
  
      case reportsTypes.SET_FILTER_OPTIONS:
        return {
          ...state,
          filterOptions: payload,
        };
  
      case reportsTypes.RESET_FILTER:
        return {
          ...state,
          filterOptions: initialState.filterOptions,
        };

        case reportsTypes.DELETE_DATA:
          return {
            ...state,
            reportsList: [
              ...state.reportsList.filter(({ id }) => id !== payload),
            ],
          };

    default:
      return state;
  }
}
