import { activeHarvestTypes } from "./actions";

const initialState = {
  activeHarvestsList: [],
};

export function activeHarvestsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case activeHarvestTypes.SET_DATA:
      return {
        ...state,
        activeHarvestsList: payload,
      };

    case activeHarvestTypes.DELETE_HARVEST:
      return {
        ...state,
        activeHarvestsList: [...state.activeHarvestsList.filter(({ id }) => id !== payload)],
      };
    default:
      return state;
  }
}
