import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import DataGrid from "../../../ui/components/DataGrid";
import {
  formatDate,
  accessCheck,
  renderHarvestsRow,
  setSelectOptions,
} from "../../../ui/helpers/helpers";
import { Select } from "../../../ui/components/Select";
import { getHarvestList, getCurrenttHarvests } from "../Harvests/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { setActiveHarvestsList } from "./_redux/actions";
import { FilterForm } from "../../../ui/structures/FilterForm";
import { modifyHarvests } from "../HarvestsReports/helpers";
import { Loader } from "../../../ui/components/Loader";
import { getAllFarms } from "../Farms/_api";
import { getAllProducts } from "../Products/_api";
import { getAllUsers } from "../UserManagment/_api";
import { productType, statusType } from "../PlannedHarvests/constants";

const HEADINGS = [
  ["status", "Status"],
  ["id", "ID"],
  ["date", "Date"],
  ["farm", "Farm"],
  ["label_date", "Label Date"],
  ["start_time", "Start"],
  ["end_time", "Finish"],
  ["duration", "Duration"],
  ["employees_amount", "People"],
  ["product", "Product"],
  ["product_type", "Format"],
  ["product_qty", "Qty"],
];

const groupOptions = [
  { value: "supervisor", label: "Supervisor" },
  { value: "product", label: "Product" },
  { value: "none", label: "None" },
];

const filterResetValues = {
  products: [],
  farms: [],
  types: [],
  supervisors: [],
};

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [groupOption, setGroupOption] = useState("supervisor");
  const [farms, setFarms] = useState([]);
  const [products, setProducts] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [resetKey, setResetKey] = useState(false);

  const data = useSelector(({ activeHarvests }) => activeHarvests.activeHarvestsList) || [];
  const user = useSelector(({ auth: { user } }) => user);

  const getHarvests = (values, actions) => {
    let fetchHarvestList = getHarvestList;
    if (!accessCheck(user)) fetchHarvestList = getCurrenttHarvests;
    setLoading(true);
    request(() =>
      values
        ? fetchHarvestList(
            formatDate(new Date(), "date"),
            null,
            values.products,
            values.farms,
            values.types,
            values.supervisors
          )
        : fetchHarvestList(formatDate(new Date(), "date"))
    )
      .then((data) => data && modifyHarvests(data))
      .then((data) => {
        dispatch(setActiveHarvestsList(data));
        setLoading(false);
      })
      .finally(() => actions && actions.setSubmitting(false));
  };

  useEffect(() => {
    getHarvests();
    Promise.all([request(getAllFarms()), request(getAllProducts()), request(getAllUsers())]).then(
      ([farms, products, users]) => {
        if (!farms || !products || !users) return;
        setFarms(farms.filter(({ name }) => name));
        setProducts(products.filter(({ name }) => name));
        setSupervisors(() =>
          users
            .filter(({ firstname, lastname }) => firstname || lastname)
            .map(({ firstname, lastname, ...user }) => ({
              ...user,
              name: [firstname, lastname].join(" "),
            }))
        );
      }
    );
    // eslint-disable-next-line
  }, []);

  const handleChange = ({ target: { value } }) => {
    setGroupOption(value);
  };

  const sortHelper = function(a, b) {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  };

  const farmsOptions = useMemo(() => farms.map(setSelectOptions).sort(sortHelper), [farms]);

  const supervisorOptions = useMemo(() => supervisors.map(setSelectOptions).sort(sortHelper), [
    supervisors,
  ]);

  const productsOptions = useMemo(() => products.map(setSelectOptions).sort(sortHelper), [
    products,
  ]);

  const onReset = (handleReset) => () => {
    const filterPayload = { ...filterResetValues, from: formatDate(new Date(), "date") };
    handleReset({ values: filterPayload });
    getHarvests(filterPayload);
    setResetKey(new Date(Date.now()));
  };

  return (
    <>
      <Loader isOpen={loading} title="Loading harvests..." />
      <div className="row justify-content-center">
        <div className="col-12">
          <div>
            <FilterForm
              onSubmit={getHarvests}
              onReset={onReset}
              products={productsOptions}
              farms={farmsOptions}
              types={productType.sort(sortHelper)}
              supervisors={supervisorOptions}
              status={statusType}
              key={resetKey}
              title="Filter Active Harvests"
              endDateEnabled={false}
              startDateEnabled={false}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between mb-3">
              <h3 className="text-dark mb-5">Harvest List</h3>
              <div>
                <IconButton variant="contained" color="secondary" className="ml-8">
                  <InputIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
            <div className="row my-5 align-items-center">
              <div className="col-1">
                <p>Group by</p>
              </div>
              <div className="col-2">
                <Select
                  options={groupOptions}
                  value={groupOption}
                  onChange={handleChange}
                  data-testid="group-by"
                />
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              loading={loading}
              groupOption={groupOption}
              renderRow={renderHarvestsRow}
              subHeader
              isHarvestList
              editable
              deletable
              hideSelect
            />
          </div>
        </div>
      </div>
    </>
  );
}
