import config from "react-global-configuration";

export const getAllFarms = (isActive = true) => () =>
  fetch(`${config.get("ApiUrl").Rest}/farms?${isActive ? "is_active=true" : ""}`, {
    mode: "cors",
    credentials: "include",
  });

export const deleteFarm = (farmId) =>
  fetch(`${config.get("ApiUrl").Rest}/farms/${farmId}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const modifyFarm = (userData, farmId) =>
  fetch(`${config.get("ApiUrl").Rest}/farms/${farmId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
    mode: "cors",
    credentials: "include",
  });

export const createFarm = (farmData) =>
  fetch(`${config.get("ApiUrl").Rest}/farms`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(farmData),
    mode: "cors",
    credentials: "include",
  });
