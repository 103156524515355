import config from "react-global-configuration";

export const getAllEmployees = (isActive = true) => () =>
  fetch(`${config.get("ApiUrl").Rest}/employees?${isActive ? "is_active=true" : ""}`, {
    mode: "cors",
    credentials: "include",
  });

  export const getCurrentEmployees = () =>
  fetch(`${config.get("ApiUrl").Rest}/users/me/employees`, {
    mode: "cors",
    credentials: "include",
  });

export const getEmployeeById = (employeeId) =>
fetch(`${config.get("ApiUrl").Rest}/employees/${employeeId}`, {
  mode: "cors",
  credentials: "include"
})

export const deleteEmployee = (employeeId) =>
  fetch(`${config.get("ApiUrl").Rest}/employees/${employeeId}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const modifyEmployee = (employeeData, employeeId) =>
  fetch(`${config.get("ApiUrl").Rest}/employees/${employeeId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(employeeData),
    mode: "cors",
    credentials: "include",
  });

export const createEmployee = (employeesData) =>
  fetch(`${config.get("ApiUrl").Rest}/employees`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(employeesData),
    mode: "cors",
    credentials: "include",
  });

  export const deactivateEmployee = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/employees/${id}/deactivate`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
    mode: "cors",
    credentials: "include",
  });

  export const activateEmployee = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/employees/${id}/activate`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
    mode: "cors",
    credentials: "include",
  });
