import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "../../../../ui/components/Input";

export const ProductQty = ({ products = [], data = {}, setMaxQty, handleUpdateField }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleQuantityChange = (e, setValue) => {
    handleUpdateField("product_qty", e.target.value, setValue);
  };

  useEffect(() => {
    if (!values.product_type) return;
    const productMaxQty = (products.find(
      (product) => String(product.id) === String(values.product_id)
    ) || {})[values.product_type.toLowerCase() + "_type_maximum_limit"];
    if (!productMaxQty) {
      setMaxQty(10000000);
      return;
    }
    setMaxQty(productMaxQty);
    // eslint-disable-next-line
  }, [values.product_type, values.product_id]);

  return (
    <>
      <MappedInput
        value={data.product_qty}
        onChange={(e) => handleQuantityChange(e, setFieldValue)}
        placeholder="Product Quantity"
        name="product_qty"
      />
    </>
  );
};
