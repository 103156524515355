import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Dialog, DialogContent } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles({
  icon: {
    fontSize: "13rem",
    marginBottom: "3rem",
  },
  content: {
    backgroundColor: "rgba(196, 196, 196, 0.75)",
  },
  root: {
    backgroundColor: "rgba(196, 196, 196, 0.75)",
    top: "30px !important",
    bottom: "30px !important",
    right: "30px !important",
    left: "30px !important",
  },
});

export function ModalError({ onClose, isOpen, message }) {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={isOpen} classes={{ root: classes.root }}>
      <DialogContent classes={{ root: classes.content }}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="text-center m-10 font-weight-bold">
            <ErrorOutlineIcon classes={{ root: classes.icon }} />
            <h5 className="mb-5">{message}</h5>
            <h5>Please contact your system administartor.</h5>
            <button
              className="px-15 mt-7 btn btn-primary"
              onClick={onClose}
              data-testid="close"
            >
              Exit
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
