import { useSelector } from "react-redux";
import { AVAILABLE_ROLES } from "../../../app/constants";

export const AccessControl = ({ desiredRoll, children, elseContent }) => {
  const user = useSelector(({ auth: { user } }) => user) || {};

  const isAdmin = user.role === "admin";
  const isSupervisor = user.role === "supervisor";
  const isManager = user.role === "manager";

  if (isAdmin && desiredRoll === AVAILABLE_ROLES.ADMIN) {
    return children;
  }

  if (isSupervisor && desiredRoll === AVAILABLE_ROLES.SUPERVISOR) {
    return children;
  }

  if (isManager && desiredRoll === AVAILABLE_ROLES.MANAGER) {
    return children;
  }

  return elseContent ? elseContent : null;
};
