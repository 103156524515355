import React, { useState, useEffect, useMemo } from "react";
import { ErrorOutline as ErrorOutlineIcon } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import ClearIcon from "@material-ui/icons/Clear";
import { GridItem } from "./GridItem";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getAllFarms } from "../../Farms/_api";
import { getAllUsers } from "../../UserManagment/_api";
import { setSelectOptions, sortHelper } from "../../../../ui/helpers/helpers";

export const EditableGrid = ({
  data = [],
  handleNewItem,
  handleRemoveItem,
  setData,
  failedItems = [],
  products = [],
}) => {
  const { request } = useFetch();

  const [supervisors, setSupervisors] = useState([]);
  const [farms, setFarms] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([request(getAllFarms()), request(getAllUsers())])
      .then(([farms, users]) => {
        if (!farms) return;
        setFarms(farms);
        users && setSupervisors(users.filter(({ role, firstname }) => role && firstname));
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const supervisorOptions = useMemo(
    () =>
      supervisors
        .map(({ id, firstname, lastname }) => {
          return {
            value: id,
            label: [firstname, lastname].join(" "),
            key: id,
          };
        })
        .sort(sortHelper),
    [supervisors]
  );

  const farmsOptions = useMemo(() => farms.map(setSelectOptions).sort(sortHelper), [farms]);

  const productsOptions = useMemo(() => products.map(setSelectOptions).sort(sortHelper), [
    products,
  ]);

  return (
    <div>
      <div className="overflow-auto flex-grow-1 w-100">
        {loading ? (
          <Skeleton variant="rect" width={"100%"} height={420} />
        ) : (
          data.map((item) => (
            <div key={item.id} className="w-100 bg-white rounded mb-10 py-7 px-10">
              {data.length > 1 ? (
                <div className="text-right cursor-pointer mb-2">
                  {
                    <ClearIcon
                      size="large"
                      color="secondary"
                      onClick={() => handleRemoveItem(item.id)}
                    />
                  }
                </div>
              ) : null}
              <table style={{ minWidth: "700px" }} className="w-100">
                <thead>
                  <tr className="border-bottom">
                    <th className="px-2">Product</th>
                    <th className="px-2">Farm Location</th>
                    <th className="px-2">Product Format</th>
                    <th className="px-2">Supervisor</th>
                    <th></th>
                    <th className="px-2">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <GridItem
                    data={item}
                    supervisorOptions={supervisorOptions}
                    farmsOptions={farmsOptions}
                    productsOptions={productsOptions}
                    products={products}
                    setData={setData}
                    itemsList={data}
                    handleNewItem={handleNewItem}
                  />
                </tbody>
              </table>
              {failedItems.includes(item.id) ? (
                <div className="text-danger mt-5">
                  <ErrorOutlineIcon className="text-danger mr-3" fontSize="large" />
                  Failed
                </div>
              ) : (
                <span></span>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
