import { userTypes } from "./actions";

const initialState = {
  usersList: [],
};

export function usersReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case userTypes.SET_DATA:
      return {
        ...state,
        usersList: payload,
      };

    case userTypes.DELETE_USER:
      return {
        ...state,
        usersList: [...state.usersList.filter(({ id }) => id !== payload)],
      };

    default:
      return state;
  }
}
