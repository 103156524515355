export const productType = [
  { value: "bunch", label: "Bunch" },
  { value: "loose", label: "Loose" },
  { value: "bundles", label: "Bundles" },
  { value: "loose_kg", label: "Loose (kg)" },
  { value: "crates", label: "Crates" },
  { value: "bin", label: "Bin" },
];

export const statusType = [
  { key: "complete", value: "complete", label: "Complete" },
  { key: "in_progress", value: "in_progress", label: "In Progress" },
  { key: "not_started", value: "not_started", label: "Not Yet Started" },
];

export const ratingOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

export const initialValues = {
  product_type: "",
  employees_amount: "",
  product_qty: "",
  date: "",
  supervisor_id: "",
  farm_id: "",
  product_id: "",
  label_date: "",
  block_id: "",
  comment: "",
  apply_date: false,
  initial_status: "new",
};
