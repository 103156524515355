import { Button } from "@material-ui/core";
import React from "react";
import { useFormikContext } from "formik";
import { Modal } from "../../../../ui/components/Modal";
import { TextArea } from "../../../../ui/components/TextArea";

export const AddComment = ({ handleUpdateField, onClose, comment, modalOpen }) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    handleUpdateField("comment", e.target.value, setFieldValue);
  };

  const handleSubmit = () => onClose();

  const handleCancel = () => {
    setFieldValue("");
    handleUpdateField("comment", "");
    onClose();
  };

  return (
    <>
      <Modal
        maxWidth="sm"
        isOpen={modalOpen}
        submitable
        onClose={onClose}
        modalContent={
          <div className="pb-6">
            <h4 className="text-center mb-5">Leave your comment</h4>
            <TextArea
              placeholder="Comment"
              multiline="true"
              rows={6}
              value={comment}
              onChange={handleChange}
            />
            <div className="d-flex justify-content-end mt-10">
              <Button
                onClick={handleCancel}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9 mr-3"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
                data-testid="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
};
