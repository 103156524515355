import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { getUser } from "./_redux/selectors";
import { useFetch } from "../../../hooks/fetch.hook";
import { getUserById } from "./_api";
import { ReactComponent as MailIcon } from "./assets/Mail.svg";
import { getAllUsers, modifyUser, createUser } from "./_api";
import { UserForm } from "./UserForm";

export function Card({ history, match }) {
  const { request } = useFetch();

  const id = match.params.id;

  const [cardData, setCardData] = useState({});

  const [users, setUsers] = useState([]);

  const data = useSelector(getUser(id)) || {};

  useEffect(() => {
    request(getAllUsers()).then((data) => data && setUsers(data.map(({ email }) => email)));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data.length) {
      id && request(getUserById, id).then((data) => data && setCardData(data));
    } else setCardData(data);
  // eslint-disable-next-line
  }, [id])

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const handleClose = () => {
    history.push("/administration/user-management");
  };

  const handleSubmit = (values, { setSubmitting }) => {
    request(id ? modifyUser : createUser, values, id)
      .then((data) => data && history.push(`/administration/user-management`))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        {cardData && (
          <div className="d-flex justify-content-between align-items-center bg-white rounded px-13 py-6 mb-10">
            <div>
              <div className="font-weight-bold mb-3">
                {cardData.firstname} {cardData.lastname}
              </div>
              <div>
                <MailIcon /> {cardData.email}
              </div>
            </div>
          </div>
        )}
        <UserForm
          data={cardData}
          users={users}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

