export const SEARCH_DATA = [
  ["firstname", "First Name"],
  ["lastname", "Last Name"],
  ["nickname", "Nickname"],
];

export const initialValues = {
  firstname: "",
  lastname: "",
  mobile: "+61",
  nickname: "",
  supervisor_ids: [],
};

export const keyMap = (data) => ({
  "First Name": data.map((item) => item.firstname),
  "Last Name": data.map((item) => item.lastname),
  Nickname: data.map((item) => item.nickname),
})