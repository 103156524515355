const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_bE7SWDpA4",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "7imdq34pmtbiithjdqlsi5dv73",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: "nonp.honestharvest.com",
      // OPTIONAL - Cookie path
      //path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
    },
  },
  ApiUrl: {
    Rest: "https://api.nonp.honestharvest.com",
    WebSocket: "https://api.nonp.honestharvest.com",
  },
  // TODO - move to API lookup. This really isn't ideal as it can leak customer association information.
  Branding: {
    LoginBackground: {
      "localhost.nonp.honestharvest.com": "/media/brands/green.png",
      "portal.nonp.honestharvest.com": "/media/brands/green.png",
    },
  },
};

export default config;
