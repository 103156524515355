import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import Skeleton from "@material-ui/lab/Skeleton";
import { modifyHarvest, getHarvestById } from "../Harvests/_api";
import { getAllFarms } from "../Farms/_api";
import { getAllProducts } from "../Products/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  formatDate,
  removeEmpty,
  accessCheck,
  setSelectOptions,
  setUtcDate,
} from "../../../ui/helpers/helpers";
import { getHarvest } from "../Harvests/_redux/selectors";
import { HarvestReportsForm } from "./HarvestReportForm";
import { updateHarvestReports } from "./_redux/actions";
import { modifyHarvestObject } from "./helpers";

export function Card({ history, match }) {
  const { request } = useFetch();

  const dispatch = useDispatch();

  const [cardData, setCardData] = useState({});

  const harvestId = match.params.id;

  const data = useSelector(getHarvest(harvestId)) || {};

  const user = useSelector(({ auth: { user } }) => user);

  const [farms, setFarms] = useState([]);
  const [products, setProducts] = useState([]);

  const transofrmHarvest = ({ date, label_date, ...data }) => {
    return {
      ...data,
      date: date && new Date(date),
      start_time: data.start_time && setUtcDate(data.start_time),
      end_time: data.end_time && setUtcDate(data.end_time),
      label_date: label_date && new Date(label_date),
    };
  };

  useEffect(() => {
    if (!Object.keys(data).length && harvestId) {
      request(getHarvestById, harvestId, accessCheck(user)).then(
        (data) => data && setCardData(transofrmHarvest(data))
      );
    } else setCardData(transofrmHarvest(data));
    Promise.all([request(getAllFarms()), request(getAllProducts())]).then(([farms, products]) => {
      if (!farms || !products) return;
      setFarms(farms);
      setProducts(products);
    });
    // eslint-disable-next-line
  }, []);

  const handleClose = () => history.goBack();

  const handleSubmit = (values, { setSubmitting }) => {
    removeEmpty(values);
    request(
      modifyHarvest,
      {
        ...values,
        supervisor_id: get(cardData, "supervisor_id"),
        date: formatDate(values.date, "date"),
        start_time: values.start_time && formatDate(values.start_time),
        end_time: values.end_time && formatDate(values.end_time),
      },
      harvestId,
      accessCheck(user)
    )
      .then((data) => {
        if (!data) return;
        dispatch(updateHarvestReports(modifyHarvestObject(data)));
        history.push(`/reports/total-reports`);
      })
      .finally(() => setSubmitting(false));
  };

  const sortHelper = function(a, b) {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  };

  const farmsOptions = useMemo(() => farms.map(setSelectOptions).sort(sortHelper), [farms]);

  const productsOptions = useMemo(() => products.map(setSelectOptions).sort(sortHelper), [
    products,
  ]);

  if (harvestId && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <HarvestReportsForm
      data={cardData}
      handleSubmit={handleSubmit}
      harvestId={harvestId}
      user={user}
      products={products}
      productsOptions={productsOptions}
      farms={farmsOptions}
      handleClose={handleClose}
    />
  );
}
