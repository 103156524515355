import React, { useMemo } from "react";
import { Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MappedInput } from "../../../ui/components/Input";
import Tabs from "../../../ui/components/Tabs";
import { MappedSelect } from "../../../ui/components/Select";
import { initialValues } from "./constants";

export function EmployeeProfileDetails({
  data,
  setData,
  handleSubmit,
  handleClose,
  employees,
  users,
}) {

  const findDuplicate = (value) => {
    return employees.find((nickname) => nickname === value && nickname !== data.nickname);
  };

  const usersOptions = useMemo(
    () =>
      users.map(({ id, firstname, lastname }) => ({
        value: id,
        label: [firstname, lastname].join(" "),
        key: id,
      })),
    [users]
  );

  return (
    <Formik
      initialValues={Object.keys(data).length ? data : initialValues}
      validationSchema={Yup.object({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        mobile: Yup.string().required()
        .min(10, "Must be at least 10 symbols")
        .matches(/(^\+{1})/, "Must start with country code")
        .matches(/(^\+{1}[0-9]+$)/, "Must contain only numbers"),
        supervisor_ids: Yup.string(),
        nickname: Yup.string()
          .required()
          .test(
            "nickname duplicate",
            "nickname already exists",
            (value) => value !== findDuplicate(value)
          ),
      })}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between mb-3">
            <div className="w-50 mr-3">
              <div className="bg-white rounded">
                <div className="px-15 py-10">
                  <h6 className="mb-10">Employees Details</h6>
                  <div className="mb-10 text-center">Account information</div>

                  <div className="row mb-3">
                    <div className="col-3">
                      <p className="text-right text-muted">First Name</p>
                    </div>
                    <div className="col-9 mr-auto">
                      <MappedInput
                        name="firstname"
                        wrapperClassName="w-100"
                        data-testid="firstname"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-3">
                      <p className="text-right text-muted">Last Name</p>
                    </div>
                    <div className="col-9 mr-auto">
                      <MappedInput
                        name="lastname"
                        wrapperClassName="w-100"
                        data-testid="lastname"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-3">
                      <p className="text-right text-muted">Nickname</p>
                    </div>
                    <div className="col-9 mr-auto">
                      <MappedInput
                        name="nickname"
                        wrapperClassName="w-100"
                        data-testid="nickname"
                      />
                    </div>
                  </div>

                  <div className="my-10 text-center">Contact information</div>

                  <div className="row mb-3">
                    <div className="col-3">
                      <p className="text-right text-muted">Mobile</p>
                    </div>
                    <div className="col-9 mr-auto">
                      <MappedInput
                        name="mobile"
                        icon="phone"
                        wrapperClassName="w-100"
                        data-testid="mobile"
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-end align-items-center mt-10">
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="large"
                      className="px-9 mr-3"
                      data-testid="cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      size="large"
                      className="px-9"
                      data-testid="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50 ml-3">
              <div className="d-flex flex-column rounded h-100">
                <Tabs
                  defaultActive="0"
                  tabs={[
                    {
                      label: "Butler",
                      icon: "",
                      tabPanel: (
                        <div className="px-15 py-10">
                          <h6>Default Supervisor</h6>
                          <div className="col-6 mt-10 mb-20">
                            <MappedSelect
                              multiple
                              data-testid="role"
                              options={users && usersOptions}
                              name="supervisor_ids"
                            />
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
