import React, { useCallback, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ToolTip from "../../components/ToolTip";
import get from "lodash/get";
import Skeleton from "@material-ui/lab/Skeleton";
import { uniq } from "lodash/array";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { Checkbox } from "@material-ui/core";
import AssignmentSharpIcon from "@material-ui/icons/AssignmentSharp";
import { Switch } from "@material-ui/core";
import { Button } from "@material-ui/core/";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Select } from "../Select";
import { Total } from "../../structures/Total";
import {
  millisecondsToDate,
  getDurationTotal,
  getRate,
  renderTooltipInfo,
  getTotalLunchTime,
} from "../../helpers/helpers";
import { HarvestsGrid } from "../../../app/modules/ActiveHarvests/HarvestsGrid";
import { GridHeader } from "./GridHeader";
import { GridRow } from "./GridRow";
import { tableStyle, scrollBlock, stickyTop, stickyHeader } from "./constants";
import {
  baseUnitTotalQuantity,
  calculateTotal,
} from "../../../app/modules/HarvestsReports/helpers";

export default function DataGrid({
  renderRow,
  headings = [],
  data = [],
  setData,
  selectable,
  editable,
  link,
  title,
  hideSelect,
  selected = {},
  setSelected,
  deletable,
  handleDelete,
  loading,
  setNewComment,
  setItemId,
  addRow,
  total,
  isUserList,
  switchable,
  handleSwitch,
  totalAttendances,
  isHarvestList,
  groupOption,
  subHeader,
  edited,
  hasAccess = true,
  switchDisabled,
  dataPerPage = 10,
}) {
  const [perPage, setPerPage] = useState(dataPerPage);
  const [page, setPage] = useState(1);

  const show = data.slice((page - 1) * perPage, page * perPage);
  const allSelected = show.every(({ id }) => selected[id]);

  const getUniq = (key) => {
    return uniq(data.map((item) => item[key]));
  };

  const keys = getUniq(groupOption);

  const handleChangePerPage = (e) => {
    setPerPage(e.target.value);
    selectable && setSelected({});
    setPage(1);
  };

  const dataKeys = () => {
    return data.reduce((acc, item) => {
      acc[item.key] = true;
      return Object.keys(acc);
    }, {});
  };

  const getRandomNumber = (max) => Math.floor(Math.random() * Math.floor(max));

  const setKey = (max) => {
    let random = getRandomNumber(max);
    const keysArr = dataKeys();
    if (!keysArr.find((item) => item === random)) {
      return random;
    } else setKey(max);
  };

  const handleChangePage = (_, page) => {
    setPage(page);
    selectable && setSelected({});
  };
  const handleChangeSelected = useCallback(
    (id) =>
      setSelected({
        ...selected,
        [id]: !selected[id],
        // eslint-disable-next-line
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const handleSelectAll = useCallback(() => {
    if (allSelected) {
      setSelected({});
    } else {
      setSelected(
        show.reduce((acc, item) => {
          acc[item.id] = true;
          return acc;
        }, {})
      );
    }
    // eslint-disable-next-line
  }, [allSelected, show]);

  const handleToggle = (item, isLunchToggle) => () => {
    handleSwitch(item, data, setData, isLunchToggle);
  };

  const handleSelected = (id) => () => {
    handleChangeSelected(id);
  };

  return (
    <div className="bg-white rounded" data-testid="datagrid">
      <h3 className="text-dark">{title}</h3>
      <div className="overflow-auto flex-grow-1" style={scrollBlock}>
        <table className="table" style={tableStyle}>
          <thead>
            <tr>
              {selectable && (
                <th className="align-middle px-5 bg-white" style={stickyTop}>
                  <Checkbox
                    className="p-0"
                    checked={allSelected}
                    onChange={handleSelectAll}
                    data-testid="checkbox"
                  />
                </th>
              )}
              <GridHeader headings={headings} subHeader={subHeader} />
              {edited && (
                <th className="bg-white" style={stickyHeader}>
                  Changed
                </th>
              )}
              {editable && hasAccess && (
                <th className="text-right bg-white pr-10" style={stickyHeader}>
                  Edit
                </th>
              )}
              {deletable && hasAccess && (
                <th className="bg-white" style={stickyHeader}>
                  Delete
                </th>
              )}
              {switchable && (
                <>
                  <th className="bg-white" style={stickyTop}>
                    Lunch
                  </th>
                  <th className="bg-white" style={stickyTop}>
                    Record Attendance
                  </th>
                  <th className="bg-white" style={stickyTop}>
                    Start Time
                  </th>
                  <th className="bg-white" style={stickyTop}>
                    Finish Time
                  </th>
                </>
              )}
            </tr>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                {selectable && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {headings.map((item) => (
                  <td key={item[0]}>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                ))}
                {edited && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {editable && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {deletable && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
              </tr>
            </tbody>
          ) : (
            <tbody>
              {isHarvestList
                ? keys.map((value) => (
                    <HarvestsGrid
                      data={data}
                      groupOption={groupOption}
                      headings={headings}
                      value={value}
                      key={setKey(100000)}
                    />
                  ))
                : show.map((item) => (
                    <tr
                      key={item.id || item.timestamp + item.message}
                      className="border-bottom"
                    >
                      {selectable && (
                        <td className="no-line border-0 align-middle px-5">
                          <Checkbox
                            className="p-0"
                            checked={Boolean(selected[item.id])}
                            onChange={handleSelected(item.id)}
                          />
                        </td>
                      )}
                      <GridRow
                        headings={headings}
                        item={item}
                        renderRow={renderRow}
                      />
                      {switchable && (
                        <>
                          {item.start_time ? (
                            <td className="no-line border-0 px-5 text-left">
                              <Switch
                                checked={item.has_lunch}
                                onChange={handleToggle(item, true)}
                                data-testid="lunch"
                              />
                            </td>
                          ) : (
                            <td>N/A</td>
                          )}
                          <td className="align-middle no-line border-0 px-5">
                            <Switch
                              checked={item.record}
                              onChange={handleToggle(item)}
                              data-testid="record"
                              disabled={!item.record && switchDisabled}
                            />
                          </td>
                          <td
                            className="align-middle no-line border-0 px-5"
                            data-testid="start_time"
                          >
                            {item.start_time &&
                              millisecondsToDate(item.start_time)}
                          </td>
                          <td
                            className="align-middle no-line border-0 px-5"
                            data-testid="end_time"
                          >
                            {item.end_time && millisecondsToDate(item.end_time)}
                          </td>
                        </>
                      )}
                      {edited && (
                        <td>
                          <ToolTip
                            icon={
                              <AssignmentSharpIcon
                                color="secondary"
                                fontSize="large"
                              />
                            }
                            description={renderTooltipInfo(item)}
                            title={`Last edit by ${get(item, "user", "")}`}
                          />
                        </td>
                      )}
                      {editable && hasAccess && (
                        <td className="text-right">
                          <NavLink
                            to={{
                              pathname: `/${link}/${item.id}`,
                            }}
                            data-testid="edit"
                          >
                            <Button variant="contained" className="bg-light" >
                              <EditIcon color="secondary"/>
                            </Button>
                          </NavLink>
                        </td>
                      )}
                      <td>
                        {deletable && hasAccess && (
                          <Button
                            variant="contained"
                            className="bg-light"
                            data-testid="delete"
                            disabled={isUserList ? !item.role : !item.is_active}
                            onClick={() => handleDelete(item.id)}
                          >
                            <DeleteIcon color="secondary" />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              {addRow && (
                <tr>
                  {headings.map((item) => (
                    <td key={item}></td>
                  ))}
                  <td className="text-right">
                    <Link
                      to={{
                        pathname: addRow,
                        newUser: true,
                      }}
                      data-testid="add-row"
                    >
                      <Button variant="contained" className="bg-light" >
                        <AddCircleOutlineIcon color="secondary" />
                      </Button>
                    </Link>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      <div className="mr-15 mt-4">
        {(total || totalAttendances) &&
          !loading &&
          data.length !==
            data.filter(({ duration }) => duration).length && (
            <div
              className="py-1 rounded mt-2"
              style={{ fontSize: 12, color: "#f59090" }}
            >
              This statistic is incomplete because some fields are not completed
              in the data above!
            </div>
          )}
        {total && data.length > 0 && (
          <Total
            durationTotal={getDurationTotal(data)}
            rate={getRate(data)}
            bunches={calculateTotal(data, "bunch")}
            bundles={calculateTotal(data, "bundles")}
            loose={calculateTotal(data, "loose")}
            loose_kg={calculateTotal(data, "loose_kg")}
            crates={calculateTotal(data, "crates")}
            bin={calculateTotal(data, "bin")}
            base_unit_total={baseUnitTotalQuantity(data)}
            total
          />
        )}
        {totalAttendances && data.length > 0 && (
          <Total
            durationTotal={getDurationTotal(data)}
            totalLunchTime={getTotalLunchTime(data)}
            isAttendance
          />
        )}
      </div>
      <div
        className={`container mt-5 p-5 bg-white border-top d-flex 
          align-items-center
          justify-content-${hideSelect ? "center" : "between"}`}
      >
        {hideSelect ? null : Math.ceil(data.length / perPage) > 1 ? (
          <Pagination
            count={Math.ceil(data.length / perPage)}
            page={page}
            showFirstButton
            showLastButton
            className="text-primary"
            color="secondary"
            classes={{ root: "text-primary" }}
            shape="rounded"
            onChange={handleChangePage}
          />
        ) : (
          <div />
        )}

        {hideSelect ? null : (
          <>
            <div className="d-flex px-8 justify-content-center align-items-center">
              <div>
                <Select
                  className="px-2 py-1 h-auto"
                  value={perPage}
                  options={[
                    { value: 5, label: 5 },
                    { value: 10, label: 10 },
                    { value: 20, label: 20 },
                    { value: 100, label: 100 },
                  ]}
                  IconComponent={ExpandMoreIcon}
                  onChange={handleChangePerPage}
                />
              </div>
              <div className="text-muted pl-2">
                Showing {(page - 1) * perPage + 1}-
                {data.length > perPage ? page * perPage : data.length} of{" "}
                {data.length}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
