import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { subDays } from "date-fns";
import { IconButton } from "@material-ui/core";
import Stopwatch from "../../../ui/structures/Stopwatch";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";
import { harvestCardSchema } from "../../../helpers/schema";
import { MappedInput } from "../../../ui/components/Input";
import { MappedSelect } from "../../../ui/components/Select";
import { MappedTextArea } from "../../../ui/components/TextArea";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { accessCheck } from "../../../ui/helpers/helpers";
import { LabelDate } from "../../../ui/structures/LabelDate";
import { Confirmation } from "./Confirmation";
import { productType, ratingOptions } from "../PlannedHarvests/constants";
import { initialValues } from "./constants";
import { FormActions } from "../../../ui/structures/FormActions";
import { ProductQty } from "./ProductQty";

export const HarvestForm = ({
  data,
  handleSubmit,
  modalOpen,
  setModalOpen,
  startTime,
  endTime,
  harvestId,
  user,
  setStartTime,
  setEndTime,
  handleModalOpen,
  productsOptions = [],
  farms = [],
  handleClose,
  products = [],
}) => {
  const { start_time, end_time } = data;

  const [maxQty, setMaxQty] = useState(10000000);

  return (
    <Formik
      initialValues={Object.keys(data).length ? data : initialValues}
      validationSchema={harvestCardSchema(maxQty)}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <>
          <div className="bg-white rounded mb-10">
            <Confirmation
              isOpen={modalOpen}
              setOpen={setModalOpen}
              data={data}
              startTime={startTime}
              endTime={endTime}
              harvestId={harvestId}
              isAdmin={accessCheck(user)}
            />
            <div className="px-21 py-10 d-flex justify-content-between">
              <h2>{harvestId ? "Edit Harvest" : "New Harvest"}</h2>
              <Stopwatch
                setFieldValue={setFieldValue}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                start={start_time && Date.parse(start_time)}
                end={end_time}
              />
              <IconButton>
                <CopyIcon />
              </IconButton>
            </div>
          </div>
          <div className="bg-white rounded">
            <div className="d-flex justify-content-between align-items-center pt-5 px-10">
              <h5>Add Product</h5>
              {startTime && !endTime && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="mr-3"
                  onClick={handleModalOpen}
                >
                  Split Team
                </Button>
              )}
            </div>
            <hr />
            <div className="px-21 py-19">
              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Product</p>
                </div>
                <div className="col-3">
                  <MappedSelect
                    name="product_id"
                    options={productsOptions && productsOptions}
                    data-testid="product_id"
                  />
                </div>
                <div className="col-2">
                  <p className="text-right text-muted">Farm Location</p>
                </div>
                <div className="col-3">
                  <MappedSelect name="farm_id" options={farms && farms} data-testid="farm_id" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Product Format</p>
                </div>
                <div className="col-3">
                  <MappedSelect
                    name="product_type"
                    options={productType}
                    data-testid="product_type"
                  />
                </div>
                <div className="col-2">
                  <p className="text-right text-muted">BLOCK ID</p>
                </div>
                <div className="col-3">
                  <MappedInput name="block_id" wrapperClassName="w-100" data-testid="block_id" />
                </div>
              </div>

              <div className="row mb-3">
                <ProductQty products={products} setMaxQty={setMaxQty} />
                <div className="col-2">
                  <p className="text-right text-muted">Comment</p>
                </div>
                <div className="col-3">
                  <MappedTextArea
                    name="comment"
                    multiline="true"
                    rows={6}
                    disabled
                    data-testid="comment"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Number Of People</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    name="employees_amount"
                    wrapperClassName="w-100"
                    data-testid="employees_amount"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Rating</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedSelect name="rating" options={ratingOptions} data-testid="rating" />
                </div>
              </div>

              {harvestId && <LabelDate disabled />}

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Date</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedDatePicker
                    name="date"
                    dateFormat="dd/MM/yyyy"
                    testId="date"
                    minDate={subDays(new Date(Date.now()), 1)}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Start Time</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    name="start_time"
                    wrapperClassName="w-100"
                    disabled
                    data-testid="start_time"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Finish Time</p>
                </div>
                <div className="col-3">
                  <MappedInput
                    name="end_time"
                    wrapperClassName="w-100"
                    disabled
                    data-testid="end_time"
                  />
                </div>
              </div>
            </div>
            <hr className="mt-15 mb-10" />
            <FormActions
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </div>
        </>
      )}
    </Formik>
  );
};
