export const activeHarvestTypes = {
  SET_DATA: "ACTIVE_HARVESTS/SET_DATA",
  DELETE_HARVEST: "ACTIVE_HARVESTS/DELETE_HARVEST",
};

export function setActiveHarvestsList(data) {
  return {
    type: activeHarvestTypes.SET_DATA,
    payload: data,
  };
}

export function deleteHarvestData(id) {
  return {
    type: activeHarvestTypes.DELETE_HARVEST,
    payload: id,
  };
}
