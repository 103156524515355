export const SEARCH_DATA = [
  ["date", "Date"],
  ["farm", "Farm"],
  ["product", "Product"],
  ["employees_amount", "People"],
  ["product_type", "Format"],
  ["product_qty", "Qty"],
];

export const filterResetValues = {
  from: "",
  to: "",
  products: [],
  farms: [],
  types: [],
  supervisors: []
}