export const SEARCH_DATA = [
  ["date", "Date"],
  ["farm", "Farm"],
  ["product", "Product"],
  ["employees_amount", "People"],
  ["product_type", "Format"],
  ["product_qty", "Qty"],
];

export const initialValues = {
  product_type: "",
  employees_amount: "",
  rating: "",
  product_qty: "",
  date: "",
  start_time: "",
  end_time: "",
  farm_id: "",
  product_id: "",
  label_date: "",
  supervisor_id: "",
  block_id: "",
  comment: "",
};
