import React from "react";
import { TextareaAutosize } from "@material-ui/core";
import { useField } from "formik";
import { getTip } from "../../helpers/helpers";

export function TextArea({
  label,
  rows = 3,
  minRows,
  maxRows,
  tip,
  labelClassName,
  inputClassName,
  ...other
}) {
  return (
    <>
      {label && <div className={labelClassName}>{label}</div>}
      <TextareaAutosize
        className={`form-control h-100 ${inputClassName}`}
        minRows={minRows || rows}
        maxRows={maxRows || rows}
        data-testid="textarea"
        {...other}
      />
      {tip && <div>{tip}</div>}
    </>
  );
}

export function MappedTextArea(props) {
  const [field, meta] = useField(props.name);
  return <TextArea {...field} {...props} tip={getTip(meta, props.tip)} />;
}
