export const emoloyeesTypes = {
  SET_DATA: "EMPLOYEES/SET_DATA",
  DELETE_EMPLOYEE: "EMPLOYEES/DELETE_EMPLOYEE"
}

export function setEmployeesList(data) {
  return {
    type: emoloyeesTypes.SET_DATA,
    payload: data
  }
}

export function deleteEmployeeData(id) {
  return {
    type: emoloyeesTypes.DELETE_EMPLOYEE,
    payload: id
  }
}
