import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFetch } from "../../../hooks/fetch.hook";
import { getAllFarms } from "../Farms/_api";

export const AddFarm = ({ farm = {}, setFarm }) => {
  const { request } = useFetch();

  const [farms, setFarms] = useState([]);

  useEffect(() => {
    request(getAllFarms()).then((farms) => farms && setFarms(farms));
    // eslint-disable-next-line
  }, []);

  const handleChange = (_, value) => {
    setFarm(value);
  };

  return (
      <div className="w-25 mr-5">
        <Autocomplete
          options={farms}
          id="2"
          getOptionLabel={({ name }) => name}
          renderInput={(params) => (
            <TextField {...params} label="Select Farm" variant="outlined" color="secondary" />
          )}
          onChange={handleChange}
        />
      </div>
  );
};
