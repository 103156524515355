import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { usersReducer} from "../app/modules/UserManagment/_redux/reducer";
import { employeesReducer} from "../app/modules/EmployeeManagement/_redux/reducer";
import { farmsReducer} from "../app/modules/Farms/_redux/reducer";
import { productsReducer} from "../app/modules/Products/_redux/reducer";
import { harvestsReducer} from "../app/modules/Harvests/_redux/reducer";
import { activeHarvestsReducer} from "../app/modules/ActiveHarvests/_redux/reducer";
import { reportsReducer} from "../app/modules/HarvestsReports/_redux/reducer";
import { attendanceReportsReducer } from "../app/modules/AttendanceReports/_redux/reducer"

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  users: usersReducer,
  employees: employeesReducer,
  farms: farmsReducer,
  product: productsReducer,
  harvests: harvestsReducer,
  activeHarvests: activeHarvestsReducer,
  totalReports: reportsReducer,
  attendanceReports: attendanceReportsReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
