import React, { useState } from "react";
import { Tabs as MuiTabs, Tab } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import makeStyles from "@material-ui/core/styles/makeStyles";

const THEME = {
  primary: { indicatorColor: "primary", textColor: "primary" },
  secondary: { indicatorColor: "secondary", textColor: "secondary" },
};

const useStyles = makeStyles({
  root: {
    minWidth: "75px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    backgroundColor: "#F3F6F9",
  },
  labelIcon: {
    minHeight: "0px",
  },
  wrapper: {
    flexDirection: "row",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
});

export default function Tabs({
  defaultActive,
  tabs,
  excludeTab,
  theme = "secondary",
}) {
  const [active, setActive] = useState(defaultActive);
  const classes = useStyles();
  const handleChange = (_, selected) => {
    setActive(selected);
  };

  return (
    <>
      <TabContext value={active}>
        <MuiTabs
          value={active}
          onChange={handleChange}
          {...THEME[theme]}
          className="border-bottom border-secondary"
        >
          {tabs.map(({ label, icon }, index) => {
            if (label === excludeTab) {
              return null;
            } else {
              return (
                <Tab
                  value={String(index)}
                  label={<div className="p-2 mb-1">{label}</div>}
                  icon={icon}
                  key={index}
                  classes={classes}
                />
              );
            }
          })}
        </MuiTabs>
        {tabs.map(({ tabPanel }, index) => (
          <div
            role="tabpanel"
            aria-hidden={active !== String(index)}
            key={index}
            className={active === String(index) ? "flex-grow-1 bg-white" : ""}
          >
            {active === String(index) && tabPanel}
          </div>
        ))}
      </TabContext>
    </>
  );
}
