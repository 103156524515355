import React from "react";
import { Formik } from "formik";
import {
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StartDate } from "./StartDate";
import { EndDate } from "./EndDate";
import { FilterAutocomplete } from "./FilterAutocomplete";
import { Skeleton } from "@material-ui/lab";

const initialValues = {
  from: "",
  to: "",
  employees: [],
  products: [],
  farms: [],
  types: [],
  supervisors: [],
  status: [],
};

export function FilterForm({
  onSubmit,
  onReset,
  farms,
  employees,
  products,
  types,
  supervisors,
  status,
  filterOptions = initialValues,
  title = "Filter Harvest Reports",
  startDateEnabled = true,
  endDateEnabled = true,
}) {
  if ((filterOptions.farms || []).length && !farms.length) {
    return <Skeleton variant="rect" width={"100%"} height={80} />;
  }

  if ((filterOptions.products || []).length && !products.length) {
    return <Skeleton variant="rect" width={"100%"} height={80} />;
  }

  if ((filterOptions.employees || []).length && !employees.length) {
    return <Skeleton variant="rect" width={"100%"} height={80} />;
  }

  if ((filterOptions.types || []).length && !types.length) {
    return <Skeleton variant="rect" width={"100%"} height={80} />;
  }

  if ((filterOptions.supervisors || []).length && !supervisors.length) {
    return <Skeleton variant="rect" width={"100%"} height={80} />;
  }

  const handleRefreshDate = (setFieldValue) => () => {
    setFieldValue("from", "");
    setFieldValue("to", "");
  };

  return (
    <Formik enableReinitialize initialValues={filterOptions} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, resetForm, values, setFieldValue }) => (
        <div className="mb-10" data-testid="filter">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <strong>{title}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex-column w-100">
                <div className="row align-items-center my-3">
                  {startDateEnabled && <StartDate />}
                  {endDateEnabled && (
                    <>
                      <div style={{ width: 0 }}>
                        <div className="text-center text-muted">To</div>
                      </div>
                      <EndDate />
                    </>
                  )}
                  {(startDateEnabled || endDateEnabled) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="medium"
                      className="ml-3"
                      onClick={handleRefreshDate(setFieldValue)}
                    >
                      Refresh Date
                    </Button>
                  )}
                </div>
                <div>
                  <div className="d-flex my-5 justify-content-between">
                    {products && (
                      <div style={{ width: "49%" }}>
                        <FilterAutocomplete
                          name="products"
                          placeholder="Product"
                          setValue={setFieldValue}
                          multiple
                          options={products}
                          defaultValueField="value"
                          data-testid="products"
                          defaultValue={filterOptions.products}
                        />
                      </div>
                    )}
                    {types && (
                      <div style={{ width: "49%" }}>
                        <FilterAutocomplete
                          name="types"
                          placeholder="Format"
                          setValue={setFieldValue}
                          options={Array.isArray(types) ? types : []}
                          defaultValueField="value"
                          data-testid="types"
                          multiple
                          defaultValue={filterOptions.types}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex my-5 justify-content-between">
                    {farms && (
                      <div style={{ width: "49%" }}>
                        <FilterAutocomplete
                          name="farms"
                          placeholder="Farm"
                          setValue={setFieldValue}
                          options={farms}
                          defaultValueField="value"
                          data-testid="farms"
                          multiple
                          defaultValue={filterOptions.farms}
                        />
                      </div>
                    )}
                    {supervisors && (
                      <div style={{ width: "49%" }}>
                        <FilterAutocomplete
                          name="supervisors"
                          placeholder="Supervisor"
                          setValue={setFieldValue}
                          options={supervisors}
                          defaultValueField="value"
                          data-testid="supervisors"
                          multiple
                          defaultValue={filterOptions.supervisors}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex my-5 justify-content-between">
                    {employees && (
                      <div style={{ width: "49%" }}>
                        <FilterAutocomplete
                          name="employees"
                          placeholder="Employee"
                          setValue={setFieldValue}
                          options={employees}
                          defaultValueField="value"
                          data-testid="employees"
                          defaultValue={filterOptions.employees}
                          multiple
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row align-items-center justify-content-end mr-1 mb-5">
                  <Button
                    onClick={onReset(resetForm)}
                    disabled={isSubmitting}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className="mr-3"
                    data-testid="cancel"
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                    size="large"
                    data-testid="submit"
                    disabled={isSubmitting}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Formik>
  );
}
