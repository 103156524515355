import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedDatePicker } from "../../components/DatePicker";
import { formatDate } from "../../helpers/helpers";

export const EndDate = () => {
  const { values } = useFormikContext();

  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    const threeMonthInMs = 7889400000;
    let startDate = values.from;
    if (!values.from) startDate = new Date(Date.now());
    setMaxDate(new Date(Date.parse(startDate) + threeMonthInMs));
    // eslint-disable-next-line
  }, [values.from]);

  return (
    <div
      style={{
        flex: "0 0 125px",
        maxWidth: 125,
        padding: "0 0 0 25px",
        marginRight: 10,
      }}
    >
      <MappedDatePicker
        name="to"
        className="border border-secnodary"
        placeholderText={formatDate(Date.now(), "date", "dd/MM/yyyy")}
        maxDate={maxDate}
      />
    </div>
  );
};
