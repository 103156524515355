import React from "react";
import { stickyHeader } from "./constants";

export const GridHeader = ({ headings, subHeader }) => {
  return (
    <>
      {subHeader && <th></th>}
      {headings.map(([key, header]) => (
        <th key={key} className="text-dark px-2 bg-white" style={stickyHeader}>
          {header}
        </th>
      ))}
    </>
  );
};
