
export const reportsTypes = {
  SET_DATA: "HARVEST_REPORTS/SET_DATA",
  SET_FILTER_OPTIONS: "HARVEST_REPORTS/SET_FILTER_OPTIONS",
  RESET_FILTER: "HARVEST_REPORTS/RESET_FILTER",
  UPDATE_DATA: "HARVEST_REPORTS/UPDATE_DATA",
  DELETE_DATA: "HARVEST_REPORTS/DELETE_DATA",
}

export function setTotalReports(data) {
  return {
    type: reportsTypes.SET_DATA,
    payload: data
  }
}

export function setFilterOptions(data) {
  return {
    type: reportsTypes.SET_FILTER_OPTIONS,
    payload: data,
  };
}

export function resetFilterOptions() {
  return {
    type: reportsTypes.RESET_FILTER,
  };
}

export function updateHarvestReports(data) {
  return {
    type: reportsTypes.UPDATE_DATA,
    payload: data
  };
}

export function deleteHarvestReport(id) {
  return {
    type: reportsTypes.DELETE_DATA,
    payload: id,
  };
}
