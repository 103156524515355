import React, { useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Collapse } from "@material-ui/core/";
import { IconButton } from "@material-ui/core";
import { Info as InfoIcon, Close as CloseIcon } from "@material-ui/icons";

export const AlertBar = ({ severity = "info", message }) => {
  return (
    <div className="w-100">
      <Alert elevation={6} variant="filled" severity={severity} style={{ fontSize: 14 }}>
        {message}
      </Alert>
    </div>
  );
};

export function WarningAlertBar({ severity = "info", message }) {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <Collapse in={open}>
        <Alert
          severity={severity}
          icon={<InfoIcon fontSize="large" style={{ fontSize: "2rem" }} />}
          variant="filled"
          action={
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          }
        >
          <AlertTitle className="ml-10 mt-1">{message}</AlertTitle>
        </Alert>
      </Collapse>
    </div>
  );
}
