import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { userProfileSchema } from "../../helpers/schema";
import { Skeleton } from "@material-ui/lab/";
import { Button } from "@material-ui/core";
import { MappedInput } from "../../ui/components/Input";
import { useFetch } from "../../hooks/fetch.hook";
import { modifyUser } from "../modules/UserManagment/_api";
import { getCurrentUser } from "../modules/Auth/_api";
import * as auth from "../../app/modules/Auth/_redux/authRedux";

const UserProfile = ({ fulfillUser }) => {
  const { request } = useFetch();

  const history = useHistory();

  const [user, setUser] = useState({});

  useEffect(() => {
    request(getCurrentUser).then((data) => data && setUser(data));
    // eslint-disable-next-line
  }, []);

  const { firstname, lastname, mobile, email, role, id } = user || {};

  if (!Object.keys(user).length) {
    return <Skeleton variant="rect" width={"100%"} height={812} />;
  }

  const initialValues = {
    firstname: firstname || "",
    lastname: lastname || "",
    mobile: mobile || "",
    email: email || "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    request(modifyUser, { ...values, role }, id)
      .then((data) => {
        if (!data) return;
        fulfillUser({ ...user, ...data });
        history.push("/dashboard");
      })
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={userProfileSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <div className="bg-white p-8 rounded">
          <div className="row">
            <div className="col col-lg-12">
              <form>
                <h3>Edit User</h3>
                <div className="form-group">
                  <label htmlFor="firstname">First Name</label>
                  <MappedInput name="firstname" wrapperClassName="w-100" />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <MappedInput name="lastname" wrapperClassName="w-100" />
                </div>
                <div className="row">
                  <div className="form-group col col-lg-6">
                    <label htmlFor="mobile">Mobile</label>
                    <MappedInput name="mobile" wrapperClassName="w-100" />
                  </div>
                  <div className="form-group col col-lg-6">
                    <label htmlFor="email">Email</label>
                    <MappedInput name="email" wrapperClassName="w-100" />
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center mt-10">
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className="px-9 mr-3"
                    onClick={handleClose}
                    data-testid="cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className="px-9"
                    disabled={isSubmitting}
                    data-testid="submit"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default connect(null, auth.actions)(UserProfile);
