import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { CustomDatePicker } from "../../../../ui/components/DatePicker";
import { Autocomplete } from "../../../../ui/components/Autocomplete";
import { initialValues, productType } from "../constants";
import { inputCellStyle } from "./constants";
import { MappedInput } from "../../../../ui/components/Input";
import { AddComment } from "./AddComment";
import { Formik } from "formik";
import { plannedHarvestSchema } from "../../../../helpers/schema";
import { MappedSwitch } from "../../../../ui/components/Switch";
import { ProductQty } from "./ProductQty";
import { LabelDate } from "./LabelDate";

export const GridItem = ({
  data = {},
  supervisorOptions = [],
  farmsOptions = [],
  productsOptions = [],
  setData,
  itemsList = [],
  products = [],
  handleNewItem,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [maxQty, setMaxQty] = useState(10000000);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleSwitch = (e, setValue) => {
    handleUpdateField("apply_date", e.target.checked, setValue);
  };

  const handleBlockChange = (e, setValue) => {
    handleUpdateField("block_id", e.target.value, setValue);
  };

  const handleEmployeesChange = (e, setValue) => {
    handleUpdateField("employees_amount", e.target.value, setValue);
  };

  const handleUpdateField = (column, value, setFieldValue) => {
    let payload = { ...data, [column]: value };
    const index = itemsList.findIndex((item) => (item || {}).id === (data || {}).id);
    if (index === -1) return;
    setFieldValue && setFieldValue(column, value);
    setData((state) => [...state.slice(0, index), payload, ...state.slice(index + 1)]);
  };

  const autoCompleteMap = [
    {
      name: "product_id",
      placeholder: "Select Product",
      options: productsOptions,
    },
    {
      name: "farm_id",
      placeholder: "Select Farm",
      options: farmsOptions,
    },
    {
      name: "product_type",
      placeholder: "Product Format",
      options: productType,
    },
    {
      name: "supervisor_id",
      placeholder: "Select Supervisor",
      options: supervisorOptions,
    },
  ];

  const handleSubmit = () => handleNewItem();

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={plannedHarvestSchema(maxQty)}
        onSubmit={handleSubmit}
      >
        {({ values, handleSubmit, setFieldValue, ...formik }) => (
          <>
            <AddComment
              handleUpdateField={handleUpdateField}
              onClose={handleModalClose}
              comment={data.comment}
              modalOpen={modalOpen}
            />
            <tr>
              {autoCompleteMap.map(({ name, placeholder, options }) => (
                <td
                  style={{ minWidth: "250px", verticalAlign: "top" }}
                  className="pt-5 px-2"
                  key={name}
                >
                  <Autocomplete
                    name={name}
                    placeholder={placeholder}
                    setValue={(name, value) => handleUpdateField(name, value, setFieldValue)}
                    size="small"
                    variant="outlined"
                    defaultValueField="value"
                    options={options}
                    withCheckbox={false}
                    disableCloseOnSelect={false}
                  />
                  {formik.touched[name] && formik.errors[name] ? (
                    <div className="text-danger">{formik.errors[name]}</div>
                  ) : null}
                </td>
              ))}
              <td></td>
              <td className="pt-5 px-2">
                <IconButton onClick={handleModalOpen}>
                  <AddIcon color="primary" />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td colSpan="6">
                <hr className="my-10" />
              </td>
            </tr>
            <tr className="border-bottom">
              <th className="px-2">Block ID</th>
              <th className="px-2">Quantity</th>
              <th className="px-2">Number of People</th>
              <th className="px-2">Apply Date to Product</th>
              <th className="px-2">Label Date</th>
              <th className="px-2">Date</th>
            </tr>
            <tr>
              <td style={inputCellStyle} className="pt-5 px-2">
                <MappedInput
                  name="block_id"
                  value={data.block_id}
                  onChange={(e) => handleBlockChange(e, setFieldValue)}
                  placeholder="Block ID"
                />
              </td>
              <td style={inputCellStyle} className="pt-5 px-2">
                <ProductQty
                  data={data}
                  handleUpdateField={handleUpdateField}
                  products={products}
                  setMaxQty={setMaxQty}
                />
              </td>
              <td style={inputCellStyle} className="pt-5 px-2">
                <MappedInput
                  value={data.employees_amount}
                  onChange={(e) => handleEmployeesChange(e, setFieldValue)}
                  placeholder="Employees Amount"
                  name="employees_amount"
                />
              </td>
              <td style={inputCellStyle} className="pt-5 px-2">
                <MappedSwitch
                  checked={data.apply_date}
                  onChange={(e) => handleSwitch(e, setFieldValue)}
                  inputProps={{ "aria-label": "controlled" }}
                  name="apply_date"
                />
              </td>
              <td
                style={{ maxWidth: "50px", minWidth: "90px", verticalAlign: "top" }}
                className="pt-5 px-2"
              >
                <LabelDate handleUpdateField={handleUpdateField} data={data} />
              </td>
              <td
                style={{ maxWidth: "50px", minWidth: "50px", verticalAlign: "top" }}
                className="pt-5 px-2"
              >
                <CustomDatePicker
                  name="date"
                  shouldCloseOnSelect
                  selected={data.date}
                  onChange={(data) => {
                    handleUpdateField("date", new Date(data), setFieldValue);
                  }}
                />
                {formik.touched.date && formik.errors.date ? (
                  <div className="text-danger">{formik.errors.date}</div>
                ) : null}
              </td>
            </tr>
            <tr>
              <td colSpan="6">
                <div className="d-flex justify-content-end align-items-center px-8 border-top mt-5 w-100">
                  <Button
                    color="secondary"
                    size="large"
                    className="px-9 mr-auto w-100"
                    tabIndex="-1"
                    startIcon={<AddIcon />}
                    onClick={handleSubmit}
                  >
                    Add New Item
                  </Button>
                </div>
              </td>
            </tr>
          </>
        )}
      </Formik>
    </>
  );
};
