export const harvestTypes = {
  SET_DATA: "HARVESTS/SET_DATA",
}

export function setHarvestsList(data) {
  return {
    type: harvestTypes.SET_DATA,
    payload: data
  }
}
