import config from "react-global-configuration";

export const getMyAttendances = () =>
  fetch(`${config.get("ApiUrl").Rest}/users/me/attendances`, {
    mode: "cors",
    credentials: "include",
  });

export const getTodayEmployees = () =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/me/today-employees?is_active=true`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyMyAttendance = (attendancetData, attendanceId) =>
  fetch(`${config.get("ApiUrl").Rest}/users/me/attendances/${attendanceId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(attendancetData),
    mode: "cors",
    credentials: "include",
  });

export const createMyAttendance = (attendancetData) =>
  fetch(`${config.get("ApiUrl").Rest}/users/me/attendances`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(attendancetData),
    mode: "cors",
    credentials: "include",
  });

export const getAllAttendances = (
  start = "",
  end = "",
  supervisor_id = [],
  employee_id = [],
  farm_id = []
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/attendances?${start &&
      "&end_after=" + start}${end && "&start_before=" + end}${
      supervisor_id.length
        ? supervisor_id.map((item) => "&supervisor_id=" + item).join("")
        : ""
    }${
      employee_id.length
        ? employee_id.map((item) => "&employee_id=" + item).join("")
        : ""
    }${
      farm_id.length ? farm_id.map((item) => "&farm_id=" + item).join("") : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getAttendanceById = (attendanceId) =>
  fetch(`${config.get("ApiUrl").Rest}/attendances/${attendanceId}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyAttendance = (attendancetData, attendanceId) =>
  fetch(`${config.get("ApiUrl").Rest}/attendances/${attendanceId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(attendancetData),
    mode: "cors",
    credentials: "include",
  });

export const exportAllAttendances = (fileName, props) =>
  fetch(
    `${config.get("ApiUrl").Rest}/attendances/export?${props[0] &&
      "&end_after=" + props[0]}${props[1] && "&start_before=" + props[1]}${
      props[2].length
        ? props[2].map((item) => "&supervisor_id=" + item).join("")
        : ""
    }${
      props[3].length
        ? props[3].map((item) => "&employee_id=" + item).join("")
        : ""
    }${
      props[4].length ? props[4].map((item) => "&farm_id=" + item).join("") : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
