import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { EmployeeProfileDetails } from "./EmployeeProfileDetails";
import { getEmployee } from "./_redux/selectors";
import { useFetch } from "../../../hooks/fetch.hook";
import { getEmployeeById, modifyEmployee, createEmployee, getAllEmployees } from "./_api";
import { getAllUsers } from "../UserManagment/_api";

export function Card({ match, history }) {
  const employeeId = match.params.id;

  const [cardData, setCardData] = useState({});
  const [employees, setEmployees] = useState([]);
  const [users, setUsers] = useState([]);

  const { request } = useFetch();

  const data = useSelector(getEmployee(employeeId));

  const handleSubmit = (values, {setSubmitting}) => {
    request(employeeId ? modifyEmployee : createEmployee, values, employeeId)
      .then((data) => data && history.push(`/administration/employee-management`))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => {
    history.push("/administration/employee-management");
  };

  useEffect(() => {
    Promise.all([request(getAllEmployees(false)), request(getAllUsers())]).then(
      ([employees, users]) => {
        if (!employees || !users) return;
        setEmployees(employees.map(({ nickname }) => nickname));
        setUsers(users.filter(({ role, firstname }) => role && firstname));
      }
    );
    if (!data) {
      employeeId &&
        request(getEmployeeById, employeeId).then((data) => {
          if (data) {
            setCardData(data);
          }
        });
    } else setCardData(data);
    // eslint-disable-next-line
  }, []);

  if (employeeId && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }


  return (
    <div className="row justify-content-center">
      <div className="col-12">
        {cardData && (
          <div className="d-flex justify-content-between align-items-center bg-white rounded px-13 py-6 mb-10">
            <div>
              <div className="font-weight-bold mb-3">
                {cardData.firstname} {cardData.lastname}
              </div>
            </div>
          </div>
        )}
        <EmployeeProfileDetails
          data={cardData}
          setData={setCardData}
          id={employeeId}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          users={users}
          employees={employees}
        />
      </div>
    </div>
  );
}
