import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ModalError } from "../../ui/components/ModalError";
import { toAbsoluteUrl } from "../../_metronic/_helpers/AssetsHelpers";

const accessCheck = (user) => user.role || user.is_superuser;

export function DashboardPage() {
  const history = useHistory();

  const user = useSelector(({ auth: { user } }) => user);

  const handleClose = () => history.push("/logout");

  return (
    <div className="bg-white w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      {!accessCheck(user) && (
        <ModalError
          isOpen
          onClose={handleClose}
          message="You have not been assigned any user permissions."
        />
      )}
      <div>
        <img src={toAbsoluteUrl("./assets/leaves.svg")} alt="leaves" />
      </div>
      <div className="font-weight-bold mt-10">Please select a menu option to get started!</div>
    </div>
  );
}
