import React from "react";

export const Total = ({
  durationTotal = [],
  rate,
  bunches,
  loose,
  bundles,
  loose_kg,
  crates,
  bin,
  total,
  base_unit_total,
  isAttendance,
}) => {
  const totalDurationTotal = durationTotal
    .filter((number) => isFinite(number))
    .reduce((acc, value) => acc + value, 0)
    .toFixed(2);

  const averageRate =
    rate &&
    (
      rate.filter((number) => isFinite(number)).reduce((acc, value) => acc + value, 0) / rate.length
    ).toFixed(2);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="d-flex flex-wrap">
      {bunches && (
        <span className="bg-light px-8 py-3 rounded mb-5 mr-5" data-testid="bunches">
          Bunches Total : {numberWithCommas(bunches)}
        </span>
      )}
      {loose && (
        <span className="bg-light px-8 py-3 rounded mb-5 mr-5" data-testid="loose">
          Loose Total : {numberWithCommas(loose)}
        </span>
      )}
      {bundles && (
        <span className="bg-light px-8 py-3 rounded mb-5 mr-5" data-testid="bundles">
          Bundles Total : {numberWithCommas(bundles)}
        </span>
      )}
      {loose_kg && (
        <span className="bg-light px-8 py-3 rounded mb-5 mr-5" data-testid="loose_kg">
          Loose (kg) Total : {numberWithCommas(loose_kg)}
        </span>
      )}
      {crates && (
        <span className="bg-light px-8 py-3 rounded mb-5 mr-5" data-testid="crates">
          Crates Total : {numberWithCommas(crates)}
        </span>
      )}
      {bin && (
        <span className="bg-light px-8 py-3 rounded mb-5 mr-5" data-testid="bin">
          Bin Total : {numberWithCommas(bin)}
        </span>
      )}
      {durationTotal.length > 0 && (
        <span className="bg-light px-8 py-3 rounded mb-5 mr-5" data-testid="duration">
          Duration Total : {numberWithCommas(totalDurationTotal)} hours
        </span>
      )}
      {!isAttendance && averageRate && (
        <span className="bg-light px-8 py-3 rounded mb-5 mr-5" data-testid="average">
          Average Harvest Rate : {isNaN(averageRate) ? "None" : averageRate}
        </span>
      )}
      {total && (
        <span className="bg-light px-8 py-3 rounded mb-5 mr-5" data-testid="total">
          Base Unit Total : {numberWithCommas(base_unit_total)}
        </span>
      )}
    </div>
  );
};
