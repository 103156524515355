import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { ProductForm } from "./ProductForm";
import { modifyProduct, createProduct, getProductById } from "./_api";
import { getProduct } from "./_redux/selectors";
import { useFetch } from "../../../hooks/fetch.hook";

function Card({ history, match }) {
  const { request } = useFetch();

  const productId = match.params.id;

  const data = useSelector(getProduct(productId)) || {};

  const [cardData, setCardData] = useState({});

  useEffect(() => {
    if (!data.length) {
      productId &&
        request(getProductById, productId).then(
          (data) => data && setCardData(data)
        );
    } else setCardData(data);
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    history.push("/administration/products-list");
  };

  if (productId && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const handleSubmit = (values) => {
    request(productId ? modifyProduct : createProduct, values, productId).then(
      (data) => data && history.push(`/administration/products-list`)
    );
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded px-13 py-6 mb-10">
          <ProductForm
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            data={cardData}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Card);
