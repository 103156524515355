import config from "react-global-configuration";

export const getAllUsers = (isActive = true) => () =>
  fetch(`${config.get("ApiUrl").Rest}/users?${isActive ? "is_active=true" : ""}`, {
    mode: "cors",
    credentials: "include",
  });

export const getUserById = (userId) =>
fetch(`${config.get("ApiUrl").Rest}/users/${userId}`, {
  mode: "cors",
  credentials: "include"
})

export const deleteUser = (userId) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${userId}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const modifyUser = (userData, userId) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${userId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
    mode: "cors",
    credentials: "include",
  });

export const createUser = (userData) =>
  fetch(`${config.get("ApiUrl").Rest}/users`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
    mode: "cors",
    credentials: "include",
  });
