import React, { useEffect, useState } from "react";
import { Chip, TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { makeStyles, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles({
  tag: {
    backgroundColor: "#aee2f2",
    color: "black",
  },
  root: {
    zIndex: 0,
  },
});

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const FilterAutocomplete = ({
  options = [],
  tip,
  placeholder = "Select option",
  loading,
  setValue,
  name,
  defaultValue,
  defaultValueField = "id",
  disabled,
  label = "label",
  disableCloseOnSelect = true,
  variant = "outlined",
  multiple = true,
  ...other
}) => {
  const classes = useStyles();

  const [selectedOptions, setSelectedOptions] = useState([]);

  const allSelected = options.length === selectedOptions.length;
  const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => {
    setSelectedOptions([]);
    setValue(name, []);
  };
  const getOptionLabel = (option) => `${option[label]}`;
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };

  const filterOptions = (items, params) => {
    if (options.length > 0) {
      const filtered = filter(items, params);
      return [{ label: "Select All", value: "select-all" }, ...filtered];
    } else if (options.length === 0) {
      const filtered = filter(items, params);
      return [...filtered];
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const onChange = (selectedOptions = []) => {
    setValue(
      name,
      selectedOptions.map(({ value }) => value)
    );
  };

  const handleChange = (_, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        handleToggleSelectAll();
        let result = [];
        result = options.filter((el) => el.value !== "select-all");
        return onChange(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  const setDefaultValue = () =>
    options.filter((item) => (defaultValue || []).includes(item[defaultValueField]));

  useEffect(() => {
    if (!defaultValue || !setValue) return;
    setValue(
      name,
      (setDefaultValue() || []).map(({ value }) => value)
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <MuiAutocomplete
        renderTags={(value, getTagProps) => {
          const numTags = value.length;
          const limitTags = 3;

          return (
            <>
              {value.slice(0, limitTags).map((option, index) => (
                <Chip {...getTagProps({ index })} key={index} label={option[label]} />
              ))}

              {numTags > limitTags && ` +${numTags - limitTags}`}
            </>
          );
        }}
        options={options}
        id={name}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        defaultValue={setDefaultValue}
        disabled={disabled}
        multiple={multiple}
        value={selectedOptions}
        disableCloseOnSelect={disableCloseOnSelect}
        classes={classes}
        filterOptions={filterOptions}
        limitTags={3}
        ListboxProps={{
          role: "list-box"
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            color="secondary"
            variant={variant}
            className={classes.root}
          />
        )}
        renderOption={(option, { selected }) => {
          const selectAllProps =
            options.length > 0 && option.value === "select-all" ? { checked: allSelected } : {};
          return (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                {...selectAllProps}
              />
              {option[label]}
            </React.Fragment>
          );
        }}
        {...other}
      />
      {tip && <div>{tip}</div>}
    </div>
  );
};
