export const tableStyle = {
  minWidth: "700px"
}

export const scrollBlock = {
  height: "65vh"
}

export const stickyTop = {
  position: "sticky",
  top: 0,
}

export const stickyHeader = {
  position: "sticky",
  top: 0,
  zIndex: 2
}