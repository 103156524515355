import React from "react";
import { MappedDatePicker } from "../components/DatePicker";

export const LabelDate = ({ disabled }) => {
  return (
    <div className="row mb-3">
      <div className="col-2">
        <p className="text-right text-muted">Label Date</p>
      </div>
      <div className="col-3 mr-auto">
        <MappedDatePicker
          name="label_date"
          disabled={disabled}
          testId="label_date"
        />
      </div>
    </div>
  );
};
