import React from "react";
import {useField} from "formik";
import { Switch } from "@material-ui/core";

export function MappedSwitch(props) {
  const [field] = useField(props.name);

  return (
    <Switch
      {...field}
      {...props}
      data-testid="switch"
    />
  );
}