import config from "react-global-configuration";

export const getAllProducts = (isActive = true) => () =>
  fetch(`${config.get("ApiUrl").Rest}/products?${isActive ? "is_active=true" : ""}`, {
    mode: "cors",
    credentials: "include",
  });

export const deleteProduct= (productId) =>
  fetch(`${config.get("ApiUrl").Rest}/products/${productId}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

  export const getProductById = (productId) =>
    fetch(`${config.get("ApiUrl").Rest}/products/${productId}`, {
      mode: "cors",
      credentials: "include",
    });

export const modifyProduct = (productData, productId) =>
  fetch(`${config.get("ApiUrl").Rest}/products/${productId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(productData),
    mode: "cors",
    credentials: "include",
  });

export const createProduct = (productData) =>
  fetch(`${config.get("ApiUrl").Rest}/products`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(productData),
    mode: "cors",
    credentials: "include",
  });
