import config from "react-global-configuration";

export const createPlannedHarvestBulk = (data, isAdmin) =>
  fetch(
    `${config.get("ApiUrl").Rest}/${
      isAdmin ? "harvests/batch-create" : "users/me/harvests/batch-create"
    }`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );
