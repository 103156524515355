import React from "react";
import DatePicker from "react-datepicker";
import { useField, useFormikContext } from "formik";
import { getTip } from "../../helpers/helpers";

export function CustomDatePicker({
  tip,
  dateFormat = "dd/MM/yyyy",
  testId = "datepicker",
  showTimeSelect,
  timeIntervals = 15,
  ...other
}) {
  return (
    <div data-testid={testId}>
      <DatePicker
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        timeIntervals={timeIntervals}
        className="form-control"
        {...other}
      />
      {tip && <div>{tip}</div>}
    </div>
  );
}

export const MappedDatePicker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <CustomDatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      className="form-control"
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      tip={getTip(meta, props.tip)}
    />
  );
};
