import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Button from "@bit/the-glue.frontendcomponents.button";
import { FormControlLabel, CircularProgress } from "@material-ui/core";
import { SearchBar } from "../../../ui/structures/SearchBar";
import DataGrid from "../../../ui/components/DataGrid";
import { ModalError } from "../../../ui/components/ModalError";
import { Checkbox } from "../../../ui/components/Checkbox";
import {
  renderUsersRow,
  handleInactiveData,
  filterSearch,
} from "../../../ui/helpers/helpers";
import { getAllUsers, deleteUser } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { setUsersList, deleteUserData } from "./_redux/actions";
import { keyMap, SEARCH_DATA } from "./constants";

const HEADINGS = [
  ["firstname", "First Name"],
  ["lastname", "Last Name"],
  ["mobile", "Mobile"],
  ["email", "Email Address"],
];

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const history = useHistory();

  const [inactive, setInactive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inactiveLoading, setInactiveLoading] = useState(false);
  const [search, setSearch] = useState({});
  const [initialData, setInitialData] = useState([]);

  const data = useSelector(({ users }) => users.usersList) || [];
  const user = useSelector(({ auth: { user } }) => user);

  const accessCheck = (user) => user.role === "admin" || user.is_superuser;

  const getUsers = (inactive) => {
    request(getAllUsers(!inactive)).then((data) => {
      if (!data) return;
      dispatch(setUsersList(data));
      setInitialData(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getUsers(inactive);
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search, isRemoveKey) => {
    setSearch(search);
    dispatch(
      setUsersList(
        filterSearch(search, isRemoveKey ? initialData : data, SEARCH_DATA)
      )
    );
  };

  const resetSearch = () => {
    getUsers(inactive);
    setSearch({});
  };

  const handleDeleteUser = (id) => {
    request(deleteUser, id).then(
      (data) => data && dispatch(deleteUserData(id))
    );
  };

  const handleInactiveUsers = (data) => {
    handleInactiveData(
      dispatch,
      Object.keys(search).length
        ? filterSearch(search, data, SEARCH_DATA)
        : data,
      setUsersList,
      setInactiveLoading
    );
    setInitialData(data);
  };
  const handleCloseModal = () => history.push("/dashboard");

  const showInactive = () => {
    setInactiveLoading(true);
    inactive
      ? request(getAllUsers(true)).then(handleInactiveUsers)
      : request(getAllUsers(false)).then(handleInactiveUsers);
    setInactive(!inactive);
  };

  return (
    <div className="row justify-content-center">
      {!accessCheck(user) && (
        <ModalError
          isOpen
          onClose={handleCloseModal}
          message="You don't have permissions to view this screen."
        />
      )}
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Users</h3>
          </div>
          <div className="mb-5">
            <Link
              to={{
                pathname: `/add-user`,
              }}
            >
              <Button label="New User" />
            </Link>
          </div>
          <div className="row">
            <div className="col-8">
              <SearchBar
                className="mb-5"
                onSearch={handleSearch}
                clearSearch={resetSearch}
                keyMap={keyMap(data)}
                placeholder="Search users..."
              />
            </div>
            <div className="col-4" style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  inactiveLoading ? (
                    <CircularProgress size="1.2rem" style={{ margin: 11 }} />
                  ) : (
                    <Checkbox
                      checked={inactive}
                      onChange={showInactive}
                      data-testid="inactive"
                    />
                  )
                }
                label="Display Inactive Users"
              />
            </div>
          </div>
          <DataGrid
            data={data}
            headings={HEADINGS}
            renderRow={renderUsersRow}
            editable
            deletable
            handleDelete={handleDeleteUser}
            loading={loading}
            isUserList
            link="users"
          />
        </div>
      </div>
    </div>
  );
}
