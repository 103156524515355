import React, { useState } from "react";
import { Button } from "@material-ui/core/";

export const ConfirmAction = ({
  handleClose,
  handleSubmit,
  id,
  warningMessage,
  confirmLabel = "Delete",
}) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <>
      <div className="p-5">
        <div className="text-center">
          <h3>{warningMessage}</h3>
        </div>
        <div className="mt-10 d-flex justify-content-end">
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            className="px-9 mr-3"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit(id, setSubmitting)}
            disabled={submitting}
            variant="contained"
            color="secondary"
            size="large"
            className="px-9"
            data-testid="submit"
          >
            {confirmLabel}
          </Button>
        </div>
      </div>
    </>
  );
};
