/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { Tooltip, withStyles, Fade } from "@material-ui/core";
import * as Version from "./version";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          Butler Market Gardens &copy;{" "}
          <span className="mr-2">{today.toString()}</span>
          All rights reserved. Privacy Policy
        </div>
        <div className="order-1 order-md-2">
          <HtmlTooltip
            title={Version.frontendVersion ? Version.frontendVersion : ""}
            arrow
            interactive
            TransitionComponent={Fade}
            placement={"left"}
            enterDelay={500}
            leaveDelay={200}
          >
            <a
              href="#"
              // target="_blank"
              // rel="noopener noreferrer"
              className="mr-3 pl-0"
            >
              <img
                src={toAbsoluteUrl("/assets/illuminance_labs_logo.png")}
                alt="illuminance-labs"
                style={{ opacity: "0.7" }}
              />
            </a>
          </HtmlTooltip>
        </div>
      </div>
    </div>
  );
}
