import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles({
  icon: {
    fontSize: "13rem",
    marginBottom: "3rem",
  },
  // content: {
  //   backgroundColor: "rgba(196, 196, 196, 0.75)",
  // },
  // root: {
  //   backgroundColor: "rgba(196, 196, 196, 0.75)",
  //   top: "30px !important",
  //   bottom: "30px !important",
  //   right: "30px !important",
  //   left: "30px !important",
  // },
});

export function ModalInfo({
  onClose,
  isOpen,
  setIsOpen,
  message,
  handleSubmit,
  values,
}) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose ? onClose : () => setIsOpen(false)}
      open={isOpen}
      classes={{ root: classes.root }}
    >
      <DialogContent classes={{ root: classes.content }}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="text-center m-10 font-weight-bold">
            <ErrorOutlineIcon classes={{ root: classes.icon }} />
            <h5 className="mb-5">{message}</h5>

            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9 mr-3"
              data-testid="cancel"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              // type="submit"
              variant="contained"
              color="secondary"
              size="large"
              className="px-9"
              // data-testid="submit"
              // disabled={isSubmitting}
              onClick={() => handleSubmit(values, true)}
            >
              Save
            </Button>
            {/* <button
              className="px-15 mt-7 btn btn-outline-primary mr-8"
              onClick={() => setIsOpen(false)}
              data-testid="close"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-15 mt-7 btn btn-primary"
              onClick={() => handleSubmit(values)}
            >
              Send Anyway
            </button> */}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
