import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import UserProfile from "./pages/UserProfile";
import { DashboardPage } from "./pages/DashboardPage";
import { UsersListPage } from "./pages/UsersListPage";
import { UserCardPage } from "./pages/UserCardPage";
import { EmployeesListPage } from "./pages/EmployeesListPage";
import { EmployeeCardPage } from "./pages/EmployeeCardPage";
import { FarmsListPage } from "./pages/FarmsListPage";
import { ProductsListPage } from "./pages/ProductsListPage";
import { ProductCardPage } from "./pages/ProductCardPage";
import { AttendanceListPage } from "./pages/AttendanceListPage";
import { HarvestsListPage } from "./pages/HarvestsListPage";
import { NewHarvestPage } from "./pages/NewHarvestPage";
import { PlannedHarvestCardPage } from "./pages/PlannedHarvestCardPage";
import { ActiveHarvestsListPage } from "./pages/ActiveHarvestsListPage";
import { TotalReportsPage } from "./pages/TotalReportsPage";
import { AttendanceReportsPage } from "./pages/AttendanceReportsPage";
import { AttendanceCardPage } from "./pages/AttendanceCardPage";
import { HarvestReportCardPage } from "./pages/HarvestReportCardPage";


export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage}/>
        <ContentRoute path="/user/edit" component={UserProfile}/>
        
        <ContentRoute path="/administration/user-management" component={UsersListPage}/>
        <Route path="/users/:id" component={UserCardPage}/>
        <ContentRoute path="/add-user" component={UserCardPage}/>

        <ContentRoute path="/administration/employee-management" component={EmployeesListPage}/>
        <Route path="/employees/:id" component={EmployeeCardPage}/>
        <ContentRoute path="/add-employee" component={EmployeeCardPage}/>
        
        <ContentRoute path="/administration/farms-list" component={FarmsListPage}/>
        
        <ContentRoute path="/administration/products-list" component={ProductsListPage}/>
        <Route path="/products/:id" component={ProductCardPage}/>
        <ContentRoute path="/add-product" component={ProductCardPage}/>

        <ContentRoute path="/attendance/attendance-list" component={AttendanceListPage}/>

        <ContentRoute path="/operations/current-harvests" component={HarvestsListPage}/>
        <Route path="/harvests/:id" component={NewHarvestPage}/>
        <ContentRoute path="/add-harvest" component={NewHarvestPage}/>
        
        <ContentRoute path="/planning/planned-harvests" component={PlannedHarvestCardPage}/>
        <ContentRoute path="/planning/active-harvests" component={ActiveHarvestsListPage}/>
        <ContentRoute path="/reports/total-reports" component={TotalReportsPage}/>
        <Route path="/total-reports/:id" component={HarvestReportCardPage}/>

        <ContentRoute path="/attendance/attendance-reports" component={AttendanceReportsPage}/>
        <Route path="/attendance-reports/:id" component={AttendanceCardPage}/>

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
