export const farmTypes = {
  SET_DATA: "FARMS/SET_DATA",
  DELETE_FARM: "FARMS/DELETE_FARM"
}

export function setFarmsList(data) {
  return {
    type: farmTypes.SET_DATA,
    payload: data
  }
}

export function deleteFarmData(id) {
  return {
    type: farmTypes.DELETE_FARM,
    payload: id
  }
}
