import React, { Component } from "react";
import { formatDate } from "../helpers/helpers";
import format from "date-fns/format";
class Stopwatch extends Component {
  state = {
    timerOn: false,
    timerStart: 0,
    timerTime: 0,
  };

  componentDidMount() {
    if (this.props.start && !this.props.end) {
      this.setState({
        timerOn: true,
        timerStart: this.props.start,
        timerTime: this.state.timerTime,
      });
      this.props.setStartTime(formatDate(this.props.start));
      this.props.setFieldValue("start_time", format(this.props.start, "HH:mm"));
      this.timer = setInterval(() => {
        this.setState({
          timerTime: Date.now() - this.props.start,
        });
      }, 10);
    }
    if (this.props.end) {
      this.setState({
        timerTime: Date.parse(this.props.end) - this.props.start,
      });
      this.props.setFieldValue("start_time", format(this.props.start, "HH:mm"));
      this.props.setFieldValue("end_time", format(new Date(this.props.end), "HH:mm"));
      this.props.setStartTime(formatDate(this.props.start));
      this.props.setEndTime(formatDate(this.props.end))
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer = () => {
    this.setState({
      timerOn: true,
      timerTime: this.state.timerTime,
      timerStart: Date.now() - this.state.timerTime,
    });
    this.props.setFieldValue("start_time", format(Date.now(), "HH:mm"));
    this.props.setStartTime(formatDate(Date.now()));
    this.timer = setInterval(() => {
      this.setState({
        timerTime: Date.now() - this.state.timerStart,
      });
    }, 10);
  };

  stopTimer = () => {
    this.setState({ timerOn: false });
    clearInterval(this.timer);
    this.props.setFieldValue("end_time", format(Date.now(), "HH:mm"));
    this.props.setEndTime(formatDate(Date.now()));
  };

  resetTimer = () => {
    this.setState({
      timerStart: 0,
      timerTime: 0,
    });
  };

  render() {
    const { timerTime, timerOn } = this.state;
    let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    let hours = ("0" + Math.floor(timerTime / 3600000)).slice(-2);
    return (
      <div className="d-flex justify-content-between">
        {timerOn === false && timerTime === 0 && (
          <button
            onClick={this.startTimer}
            className="px-14 py=2 mr-5 btn btn-success font-weight-bold"
            disabled={this.props.end ? true : false}
          >
            START
          </button>
        )}
        {/* {timerOn === false && timerTime > 0 && (
          <button
            onClick={this.startTimer}
            className="px-14 py=2 mr-5 btn btn-success font-weight-bold"
          >
            RESUME
          </button>
        )} */}
        {timerOn === true && (
          <button
            onClick={this.stopTimer}
            className="px-14 py=2 mr-5 btn btn-danger font-weight-bold"
          >
            STOP
          </button>
        )}
        {/* {timerOn === false && timerTime > 0 && (
          <button
            onClick={this.resetTimer}
            className="px-14 py=2 mr-5 btn btn-danger font-weight-bold"
          >
            RESET
          </button>
        )} */}
        <div className="bg-primary text-white rounded d-flex align-items-center px-15">
          {hours} : {minutes} : {seconds}
        </div>
      </div>
    );
  }
}

export default Stopwatch;
