import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputIcon from "@material-ui/icons/Input";
import DataGrid from "../../../ui/components/DataGrid";
import {
  renderHarvestsRow,
  setSelectOptions,
  sortByDate,
  validCheckDateFormat,
} from "../../../ui/helpers/helpers";
import { getHarvestList } from "../Harvests/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  resetFilterOptions,
  setFilterOptions,
  setTotalReports,
} from "./_redux/actions";
import { FilterForm } from "../../../ui/structures/FilterForm";
import { getAllFarms } from "../Farms/_api";
import { getAllProducts } from "../Products/_api";
import { getAllUsers } from "../UserManagment/_api";
import { productType, statusType } from "../PlannedHarvests/constants";
import { modifyHarvests } from "./helpers";
import { filterResetValues } from "./constants";
import ToolTip from "../../../ui/components/ToolTip";
import { Loader } from "../../../ui/components/Loader";
import { WarningAlertBar } from "../../../ui/components/AlertBar";
import { CSVLink } from "react-csv";
import { formatDate } from "../../../ui/helpers/helpers";

const HEADINGS = [
  ["status", "Status"],
  ["date", "Date"],
  ["product", "Product"],
  ["product_qty", "Quantity"],
  ["base_unit_total", "Base Unit Qty"],
  ["product_type", "Format"],
  ["employees_amount", "People"],
  ["duration", "Duration"],
  ["duration_total_to_ui", "Total Duration"],
  ["duration_total", "Total Time in Hours"],
  ["start_time", "Start"],
  ["end_time", "Finish"],
  ["rate", "Harvest Rate"],
  ["rating", "Rating"],
  ["farm", "Farm Location"],
  ["supervisor", "Supervisor"],
  ["block_id", "Block ID"],
];

const HEADINGS_MAP = [...HEADINGS, ["duration_total", "Total Duration"]].map(
  function(row) {
    return ["key", "label"].reduce(function(obj, key, i) {
      obj[key] = row[i];
      return obj;
    }, {});
  }
);

export function List() {
  const { request } = useFetch();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [farms, setFarms] = useState([]);
  const [products, setProducts] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [resetKey, setResetKey] = useState(false);

  const data =
    useSelector(({ totalReports }) => totalReports.reportsList) || [];
  const filterOptions = useSelector(
    ({ totalReports: { filterOptions } }) => filterOptions
  );

  const getHarvests = (values, actions) => {
    setLoading(true);
    request(() =>
      values
        ? getHarvestList(
            validCheckDateFormat(values.from, "date"),
            validCheckDateFormat(values.to, "date"),
            values.products,
            values.farms,
            values.types,
            values.supervisors,
            values.status
          )
        : getHarvestList()
    )
      .then((data) => data && modifyHarvests(data).sort(sortByDate))
      .then((data) => {
        dispatch(setTotalReports(data));
        values && dispatch(setFilterOptions(values));
        setLoading(false);
      })
      .finally(() => actions && actions.setSubmitting(false));
  };

  useEffect(() => {
    if (!data.length) getHarvests(filterOptions);
    Promise.all([
      request(getAllFarms()),
      request(getAllProducts()),
      request(getAllUsers()),
    ]).then(([farms, products, users]) => {
      if (!farms || !products || !users) return;
      setFarms(farms.filter(({ name }) => name));
      setProducts(products.filter(({ name }) => name));
      setSupervisors(() =>
        users
          .filter(({ firstname, lastname }) => firstname || lastname)
          .map(({ firstname, lastname, ...user }) => ({
            ...user,
            name: [firstname, lastname].join(" "),
          }))
      );
    });
    // eslint-disable-next-line
  }, []);

  const onReset = (handleReset) => () => {
    const threeMonthInMs = 7889400000;
    const filterPayload = {
      ...filterResetValues,
      from: new Date(Date.now() - threeMonthInMs),
    };
    handleReset({ values: filterPayload });
    getHarvests(filterPayload);
    dispatch(resetFilterOptions());
    setResetKey(new Date(Date.now()));
  };

  const sortHelper = function(a, b) {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  };

  const farmsOptions = useMemo(
    () => farms.map(setSelectOptions).sort(sortHelper),
    [farms]
  );

  const supervisorOptions = useMemo(
    () => supervisors.map(setSelectOptions).sort(sortHelper),
    [supervisors]
  );

  const productsOptions = useMemo(
    () => products.map(setSelectOptions).sort(sortHelper),
    [products]
  );

  return (
    <>
      <WarningAlertBar message="Due to a large amount of data reports can be filtered only within 3 month range" />
      <Loader isOpen={loading} title="Loading reports..." />
      <div className="row justify-content-center">
        <div className="col-12">
          <div>
            <FilterForm
              onSubmit={getHarvests}
              onReset={onReset}
              products={productsOptions}
              farms={farmsOptions}
              types={productType.sort(sortHelper)}
              supervisors={supervisorOptions}
              status={statusType}
              filterOptions={filterOptions}
              key={resetKey}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between mb-3">
              <h3 className="text-dark mb-5">Harvest Total Reports</h3>
              <div>
                <CSVLink
                  data={data}
                  headers={HEADINGS_MAP}
                  filename={`harvest-total-report-${formatDate(
                    Date.now(),
                    "date",
                    "dd/MM/yyyy"
                  )}.csv`}
                >
                  <ToolTip
                    icon={<InputIcon fontSize="large" />}
                    title="Export Harvests Data to CSV"
                  ></ToolTip>
                </CSVLink>
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderRow={renderHarvestsRow}
              loading={loading}
              dataPerPage="100"
              total
              link="total-reports"
              editable
            />
          </div>
        </div>
      </div>
    </>
  );
}
