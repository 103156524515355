/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { AccessControl } from "../../../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../../../../app/constants";
import { ADMIN_MENU, MANAGER_MENU, SUPERVISOR_MENU } from "./constants";

const ITEM_TYPES = {
  link: "link",
  heading: "heading",
  menu: "menu",
};

export function AsideMenuList({ layoutProps }) {
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <AccessControl desiredRoll={AVAILABLE_ROLES.ADMIN}>
          {ADMIN_MENU.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </AccessControl>
        <AccessControl desiredRoll={AVAILABLE_ROLES.MANAGER}>
          {MANAGER_MENU.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </AccessControl>
        <AccessControl desiredRoll={AVAILABLE_ROLES.SUPERVISOR}>
          {SUPERVISOR_MENU.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </AccessControl>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

function MenuItem({ item, subMenu }) {
  const location = useLocation();
  const getMenuItemActive = useCallback(
    (url) => {
      return checkIsActive(location, url) ? " menu-item-active menu-item-open " : "";
    },
    [location]
  );
  const { type } = item;
  switch (type) {
    case ITEM_TYPES.link:
      return (
        <li className={`menu-item ${getMenuItemActive(item.to)}`}>
          <NavLink className="menu-link" to={item.to}>
            {Boolean(subMenu) && (
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
            )}
            {Boolean(item.iconUrl) && (
              <span className="svg-icon menu-icon">
                <img src={toAbsoluteUrl(item.iconUrl)} alt="" />
              </span>
            )}
            <span className="menu-text">{item.title}</span>
            <i className="menu-arrow" />
          </NavLink>
        </li>
      );
    case ITEM_TYPES.heading:
      return (
        <li className="mx-8 mt-5">
          <span className="menu-text font-weight-bold">{item.title}</span>
        </li>
      );
    case ITEM_TYPES.menu:
      return (
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(item.to)}`}
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={item.to}>
            <span className="svg-icon menu-icon">
              <img src={toAbsoluteUrl(item.iconUrl)} alt="" />
            </span>
            <span className="menu-text">{item.title}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent">
                <span className="menu-link">
                  <span className="menu-text">{item.title}</span>
                </span>
              </li>
              {item.items.map((subItem, index) => (
                <MenuItem key={index} item={subItem} subMenu />
              ))}
            </ul>
          </div>
        </li>
      );
    default:
      return null;
  }
}
