export const reportsTypes = {
  SET_DATA: "ATTENDANCE_REPORTS/SET_DATA",
  SET_SUMMERY_DATA: "ATTENDANCE_REPORTS/SET_SUMMERY_DATA",
  SET_FILTER_OPTIONS: "ATATTENDANCE_REPORTS/SET_FILTER_OPTIONS",
  RESET_FILTER: "ATATTENDANCE_REPORTS/RESET_FILTER",
  UPDATE_DATA: "ATTENDANCE_REPORTS/UPDATE_DATA",
};

export function setAttendanceReports(data) {
  return {
    type: reportsTypes.SET_DATA,
    payload: data,
  };
}

export function setAttendanceSummeryReports(data) {
  return {
    type: reportsTypes.SET_SUMMERY_DATA,
    payload: data,
  };
}

export function setFilterOptions(data) {
  return {
    type: reportsTypes.SET_FILTER_OPTIONS,
    payload: data,
  };
}

export function resetFilterOptions() {
  return {
    type: reportsTypes.RESET_FILTER,
  };
}

export function updateAttendaceReport(data) {
  return {
    type: reportsTypes.UPDATE_DATA,
    payload: data,
  };
}
