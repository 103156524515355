export const productTypes = {
  SET_DATA: "PRODUCTS/SET_DATA",
  DELETE_PRODUCT: "PRODUCTS/DELETE_PRODUCT"
}

export function setProductsList(data) {
  return {
    type: productTypes.SET_DATA,
    payload: data
  }
}

export function deleteProductData(id) {
  return {
    type: productTypes.DELETE_PRODUCT,
    payload: id
  }
}
