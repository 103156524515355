import { productTypes } from "./actions";

const initialState = {
  productsList: [],
};

export function productsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case productTypes.SET_DATA:
      return {
        ...state,
        productsList: payload,
      };

    case productTypes.DELETE_PRODUCT:
      return {
        ...state,
        productsList: [...state.productsList.filter(({ id }) => id !== payload)],
      };

    default:
      return state;
  }
}
