export const SEARCH_DATA = [
  ["firstname", "First Name"],
  ["lastname", "Last Name"],
  ["email", "Email Address"],
];

export const userRoles = [
  {
    value: "supervisor",
    label: "Supervisor",
  },
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "manager",
    label: "Manager",
  },
];

export const initialValues = {
  firstname: "",
  lastname: "",
  mobile: "+61",
  email: "",
  role: "",
};

export const keyMap = (data) => ({
  "First Name": data.map((item) => item.firstname),
  "Last Name": data.map((item) => item.lastname),
  "Email Address": data.map((item) => item.email),
})