import React, { useState } from "react";
import { Formik } from "formik";
import { IconButton, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ReactComponent as CopyIcon } from "../Harvests/assets/copy.svg";
import { harvestCardSchema } from "../../../helpers/schema";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { LabelDate } from "../../../ui/structures/LabelDate";
import { MappedInput } from "../../../ui/components/Input";
import { MappedSelect } from "../../../ui/components/Select";
import { productType } from "../PlannedHarvests/constants";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmAction } from "../../../ui/components/ConfirmAction";
import { useFetch } from "../../../hooks/fetch.hook";
import { deleteHarvest } from "../Harvests/_api";
import { deleteHarvestReport } from "./_redux/actions";
import { ProductQty } from "../Harvests/ProductQty";

export const HarvestReportsForm = ({
  data = {},
  handleSubmit,
  harvestId,
  handleClose,
  products = [],
  productsOptions = [],
  farms = [],
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [maxQty, setMaxQty] = useState(10000000);

  const { request } = useFetch();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  const handleDelete = (id, setSubmitting) => {
    setSubmitting(true);
    request(deleteHarvest, id)
      .then((data) => {
        if (!data) return;
        dispatch(deleteHarvestReport(id));
        history.push("/reports/total-reports");
      })
      .finally(() => {
        setSubmitting(false);
        handleModalClose();
      });
  };

  return (
    <Formik
      initialValues={data}
      validationSchema={harvestCardSchema(maxQty)}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          {modalOpen && (
            <Modal
              maxWidth="sm"
              isOpen={modalOpen}
              submitable
              onClose={handleModalClose}
              modalContent={
                <ConfirmAction
                  handleClose={handleModalClose}
                  confirmLabel="Delete"
                  warningMessage={
                    <>
                      <div className="mb-4 text-danger">WARNING!</div>
                      <div>Are you sure you want to delete this harvest?</div>
                    </>
                  }
                  id={data.id}
                  handleSubmit={handleDelete}
                />
              }
            />
          )}
          <div className="bg-white rounded mb-10">
            <div className="px-21 py-10 d-flex justify-content-between">
              <h2>Edit Harvest Report</h2>
              <IconButton>
                <CopyIcon />
              </IconButton>
            </div>
          </div>
          <div className="bg-white rounded">
            <hr />
            <div className="px-21 py-19">
              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Product</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedSelect name="product_id" options={productsOptions} />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Product Format</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedSelect name="product_type" options={productType} />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Farm Location</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedSelect name="farm_id" options={farms} />
                </div>
              </div>

              <div className="row mb-3">
                <ProductQty products={products} setMaxQty={setMaxQty} />
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">People</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput name="employees_amount" wrapperClassName="w-100" />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Block ID</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput name="block_id" wrapperClassName="w-100" />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Date</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedDatePicker name="date" testId="date" />
                </div>
              </div>

              {harvestId && <LabelDate disabled />}

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Date</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedDatePicker name="date" testId="date" />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Start Time</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedDatePicker
                    name="start_time"
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    timeIntervals={10}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Finish Time</p>
                </div>
                <div className="col-3">
                  <MappedDatePicker
                    name="end_time"
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    timeIntervals={10}
                  />
                </div>
              </div>
            </div>
            <hr className="mt-15 mb-10" />
            <div className={`d-flex justify-content-end align-items-center mr-10 pb-10`}>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
                data-testid="cancel"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                onClick={handleModalOpen}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9 mr-3"
              >
                Delete
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
                data-testid="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
