import React, { useState, useMemo, useEffect } from "react";
import { FormControlLabel, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SearchBar } from "../../../ui/structures/SearchBar";
import DataGrid from "../../../ui/components/DataGrid";
import { Checkbox } from "../../../ui/components/Checkbox";
import {
  renderEmployeesRow,
  handleInactiveData,
  accessCheck,
  renderRow,
  filterSearch
} from "../../../ui/helpers/helpers";
import {
  getAllEmployees,
  deleteEmployee,
  activateEmployee,
  deactivateEmployee,
} from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { setEmployeesList, deleteEmployeeData } from "./_redux/actions";
import { SEARCH_DATA, keyMap } from "./constants";

const HEADINGS = [
  ["firstname", "First Name"],
  ["lastname", "Last Name"],
  ["mobile", "Mobile"],
  ["nickname", "Nickname"],
];

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [search, setSearch] = useState({});
  const [selected, setSelected] = useState({});
  const [inactive, setInactive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inactiveLoading, setInactiveLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);

  const data = useSelector(({ employees }) => employees.employeesList) || [];
  const user = useSelector(({ auth: { user } }) => user);

  const getEmployees = (inactive) => {
    request(getAllEmployees(!inactive)).then((data) => {
      if (!data) return;
      dispatch(setEmployeesList(data));
      setInitialData(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getEmployees(inactive);
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search, isRemoveKey) => {
    setSearch(search);
    dispatch(
      setEmployeesList(
        filterSearch(
          search,
          isRemoveKey ? initialData : data,
          SEARCH_DATA
        )
      )
    );
  };

  const resetSearch = () => {
    getEmployees(inactive);
    setSearch({});
  };

  const handleDeleteEmployee = (id) => {
    request(deleteEmployee, id).then((data) => data && dispatch(deleteEmployeeData(id)));
  };

  const handleInactiveEmployees = (data) => {
    handleInactiveData(
      dispatch,
      Object.keys(search).length ? filterSearch(search, data, SEARCH_DATA) : data,
      setEmployeesList,
      setInactiveLoading
    );
    setInitialData(data)
  };

  const showInactive = () => {
    setInactiveLoading(true);
    inactive
      ? request(getAllEmployees()).then(handleInactiveEmployees)
      : request(getAllEmployees(false)).then(handleInactiveEmployees);
    setInactive(!inactive);
  };

  const archiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const getId = () => {
    for (let key in selected) {
      if (selected[key]) {
        return key;
      }
    }
  };

  const handleArchive = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to archive this employee?"
    );
    const itemID = getId();
    if (confirmation) {
      try {
        deactivateEmployee(itemID).then((res) => {
          if (res.status === 200 || res.status === 204) {
            dispatch(deleteEmployeeData(+itemID));
            const idToString = String(itemID);
            setSelected({
              ...selected,
              [idToString]: false,
            });
          }
        });
      } catch {}
    }
  };

  const handleUnArchive = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm("Are you sure you want to reactivate this employee?");
    const itemID = getId();
    if (confirmation) {
      try {
        activateEmployee(itemID).then((res) => {
          if (res.status === 200 || res.status === 204) {
            const idToString = String(itemID);
            setSelected({
              ...selected,
              [idToString]: false,
            });
          }
        });
      } catch {}
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Employees</h3>
          </div>
          {accessCheck(user) && (
            <div className="mb-5">
              <Link
                to={{
                  pathname: `/add-employee`,
                }}
              >
                <button className="px-7 mr-5 btn btn-primary">
                  New Employee
                </button>
              </Link>
              {!inactive ? (
                <button
                  className="px-15 mr-5 btn btn-primary"
                  onClick={handleArchive}
                  disabled={archiveDisabled}
                >
                  Archive Employee
                </button>
              ) : (
                <button
                  className="px-15 mr-5 btn btn-primary"
                  onClick={handleUnArchive}
                  disabled={archiveDisabled}
                >
                  Unarchive Employee
                </button>
              )}
            </div>
          )}
          <div className="row">
            <div className="col-8">
              <SearchBar
                className="mb-5"
                onSearch={handleSearch}
                clearSearch={resetSearch}
                keyMap={keyMap(data)}
                placeholder="Search employees..."
              />
            </div>
            <div className="col-4" style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  inactiveLoading ? (
                    <CircularProgress size="1.2rem" style={{ margin: 11 }} />
                  ) : (
                    <Checkbox
                      checked={inactive}
                      onChange={() => showInactive()}
                      data-testid="inactive"
                    />
                  )
                }
                label="Display Inactive Employees"
              />
            </div>
          </div>
          <DataGrid
            data={data}
            headings={HEADINGS}
            renderRow={accessCheck(user) ? renderEmployeesRow : renderRow}
            editable
            selectable={accessCheck(user)}
            deletable
            handleDelete={handleDeleteEmployee}
            selected={selected}
            setSelected={setSelected}
            loading={loading}
            hasAccess={accessCheck(user)}
            link="employees"
          />
        </div>
      </div>
    </div>
  );
}
