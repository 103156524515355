import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core/";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { NavLink } from "react-router-dom";
import { renderHarvestsRow, accessCheck } from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { deleteHarvestData } from "./_redux/actions";
import { deleteHarvest } from "../Harvests/_api";

export const HarvestsGrid = ({ data, groupOption, headings, value, handleDelete }) => {
  const { request } = useFetch();

  const dispatch = useDispatch();

  const user = useSelector(({ auth: { user } }) => user);

  const handleDeleteHarvest = (id) => () => {
    request(deleteHarvest, id).then(() => dispatch(deleteHarvestData(id)));
  // eslint-disable-next-line
  }

  return (
    <>
      {value && (
        <tr>
          <td className="font-weight-bold">{value}</td>
        </tr>
      )}
      {data.map(
        (item) =>
          item[groupOption] === value && (
            <tr className="border-bottom" key={item.id}>
              <td></td>
              {renderHarvestsRow(headings, item)}

              <td className="text-right">
                <NavLink
                  to={{
                    pathname: `/harvests/${item.id}`,
                    isActiveHarvest: true,
                  }}
                  data-testid="edit"
                >
                  <Button variant="contained" className="bg-light">
                    <EditIcon color="secondary" />
                  </Button>
                </NavLink>
              </td>
              {accessCheck(user) && (
                <td>
                  <Button
                    variant="contained"
                    className="bg-light"
                    onClick={handleDeleteHarvest(item.id)}
                  >
                    <DeleteIcon color="secondary" data-testid="delete" />
                  </Button>
                </td>
              )}
            </tr>
          )
      )}
      {value && (
        <tr className="border-bottom">
          <td className="font-weight-bold pb-20">
            {value} :{" "}
            {data.reduce(
              (total, item) => (item[groupOption] === value ? total + item.product_qty : total),
              0
            )}
          </td>
        </tr>
      )}
    </>
  );
};
