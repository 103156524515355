import React from "react";
import { LinearProgress, Dialog, DialogContent } from "@material-ui/core";

export const Loader = ({ title = "Loading...", onClose, isOpen, maxWidth = "xs", ...other }) => {
  const handleModalClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
  };

  return (
    <Dialog
      onClose={handleModalClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={maxWidth}
      {...other}
    >
      <DialogContent className="p-0 pt-10 mx-5">
        <div className="pb-10">
          <div className="mb-2">{title}</div>
          <LinearProgress color="secondary" />
        </div>
      </DialogContent>
    </Dialog>
  );
};
