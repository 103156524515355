import React, { useRef, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Paper, Chip, IconButton } from "@material-ui/core";
import { uniq } from "lodash/array";
import { Input } from "../components/Input";

export function SearchBar({ onSearch, keyMap, className, placeholder, clearSearch }) {
  const [search, setSearch] = useState([]);
  const [keySelected, setKeySelected] = useState(null);

  const resetSearch = () => {
    clearSearch();
    setSearch([]);
    setKeySelected(null);
  };

  const input = useRef(null);

  const checkForChip = () => search.find(({value}) => value);

  const selectKey = (value) => {
    if (keySelected) {
      setKeySelected(null);
      let _search = [...search];
      const last = _search.pop();
      _search = [..._search, { key: last.key, value }];
      setSearch(_search);
      onSearch(
        _search.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {})
      );
    } else {
      setKeySelected(value);
      setSearch([...search, { key: value }]);
    }
    if (input.current) {
      input.current.blur();
      requestAnimationFrame(() => input.current.focus());
    }
  };

  const handleKeyDown = (event) => {
    const backspace = { keyCode: 8 };

    if (event.keyCode === backspace.keyCode && event.target.value === "") {
      if (keySelected === null) {
        const _search = search.slice(0, -1);

        setSearch(_search);
      } else {
        setKeySelected(null);
      }
    }
  };

  const handleChange = (_, [value], reason) => {
    if (reason !== "select-option") return;
    selectKey(value);
  };

  const handleRemove = (key) => () => {
    const _search = search.filter((i) => i.key !== key);
    setSearch(_search);
    if (keySelected) {
      setKeySelected(null);
    } else {
      onSearch(
        _search.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {}), true
      );
    }
  };

  const getOptions = (search) => {
    const selected = search.reduce((acc, i) => {
      acc[i.key] = true;
      return acc;
    }, {});
    if (search.length > 0 && !search[search.length - 1].value) {
      return uniq(
        keyMap[search[search.length - 1].key]
          .reduce((a, v) => a.concat(typeof v === "number" ? String(v): v), [])
          .filter(item => (typeof item === "string" && item.trim() !== "") || Array.isArray(item))
      );
    }
    return Object.keys(keyMap).filter((k) => {
      if (k === "Tags") return Object.keys(keyMap);
      return !selected[k];
    });
  };

  return (
    <Paper
      className={`d-flex align-items-center border border-secondary rounded ${className}`}
      elevation={0}
    >
      <SearchIcon className="m-2" color="secondary" />
      <div className="flex-shrink-0">
        {search.map(({ key, value }) => {
          if (!value) {
            return `${key}:`;
          }
          return (
            <Chip
              className="ml-3"
              key={`${key}-${value}`}
              size="small"
              label={value ? `${key}: ${value}` : key}
              onDelete={handleRemove(key)}
            />
          );
        })}
      </div>
      <Autocomplete
        className="flex-grow-1"
        value={[]}
        onKeyDown={handleKeyDown}
        multiple
        options={getOptions(search)}
        onChange={handleChange}
        openOnFocus
        id="1"
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <Input
              ref={input}
              placeholder={placeholder}
              labelClassName="mb-0"
              id="1"
              inputClassName="border-0 bg-white w-100 py-1 h-100"
              inputGroupClassName="border-0 w-100 py-1 h-100"
              data-testid="searchbar"
              inputProps={params.inputProps}
            />
          </div>
        )}
      />
      {checkForChip() && (
        <IconButton onClick={resetSearch} size="small">
          <ClearIcon className="m-2" color="secondary" />
        </IconButton>
      )}
    </Paper>
  );
}
