import React from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { Checkbox } from "../components/Checkbox";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import ToolTip from "../components/ToolTip";

export function getTip(meta = {}, tip) {
  if (meta.touched && meta.error) {
    return <span className="text-danger">{meta.error}</span>;
  }
  if (tip) {
    return <span className="text-muted">{tip}</span>;
  }
}

export function renderRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5">
      {item[key]}
    </td>
  ));
}

function isLink(key) {
  return ["name", "firstname", "lastname"].includes(key);
}

function isCheckbox(key) {
  return [
    "bunch_type_allowed",
    "loose_type_allowed",
    "bundles_type_allowed",
    "loose_kg_type_allowed",
    "crates_type_allowed",
    "bin_type_allowed",
  ].includes(key);
}

function isTimestamp(key) {
  return ["start_time", "end_time"].includes(key);
}

function isType(key) {
  return ["product_type"].includes(key);
}

function isDate(key) {
  return ["date", "label_date", "start_date", "end_date"].includes(key);
}

const isDifferenceKey = (key) =>
  ["date", "start_time", "end_time", "note", "has_lunch", "farm_id", "employee_id"].includes(key);

const toolTipMap = {
  date: "Date",
  start_time: "Start Time",
  end_time: "Finish Time",
  note: "Note",
  has_lunch: "Lunch",
  farm_id: "Farm ID",
  employee_id: "Employee ID",
};

const difference = (obj1, obj2) => {
  let diffArrayKeys = [];
  if (obj1 && obj2) {
    const newObj1 = {
      ...obj1,
      start_time: obj1.start_time && millisecondsToDate(setUtcDate(obj1.start_time)),
      end_time: obj1.end_time && millisecondsToDate(setUtcDate(obj1.end_time)),
    };

    const newObj2 = {
      ...obj2,
      start_time: obj2.start_time && millisecondsToDate(obj2.start_time),
      end_time: obj2.end_time && millisecondsToDate(obj2.end_time),
    };

    Object.keys(newObj1).forEach((key) => {
      if (newObj1[key] !== newObj2[key] && isDifferenceKey(key)) {
        if (
          isObject(newObj1[key]) ||
          isObject(newObj2[key]) ||
          isArray(newObj1[key]) ||
          isArray(newObj2[key])
        )
          return;
        diffArrayKeys.push(key + "^" + String(newObj1[key]) + "^" + String(newObj2[key]));
      }
    });
    return diffArrayKeys;
  }
};

export const renderTooltipInfo = (item) => {
  const lastVersion = item.previous_versions.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )[0];
  const diffArray = difference(lastVersion, item);
  return (
    diffArray &&
    diffArray
      .map((item) => item.split("^"))
      .map((item) => <div key={item[0]}>{`${toolTipMap[item[0]]} : ${item[1]} => ${item[2]}`}</div>)
  );
};

export function renderAttendanceReportsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5">
      {isTimestamp(key)
        ? item[key] && millisecondsToDate(item[key])
        : isDate(key)
        ? formatDateUi(item[key])
        : item[key]}
    </td>
  ));
}

export function renderUsersRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5">
      {isLink(key) ? (
        <Link className={`${item.role ? "text-dark" : "text-danger"}`} to={`/users/${item.id}`}>
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function renderEmployeesRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5">
      {isLink(key) ? (
        <Link
          className={`${item.is_active ? "text-dark" : "text-danger"}`}
          to={`/employees/${item.id}`}
        >
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function renderProductsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5">
      {isCheckbox(key) ? <Checkbox checked={item[key]} /> : item[key]}
    </td>
  ));
}

export function hasLongContentRow(key) {
  return ["product", "block_id"].includes(key);
}

export function renderHarvestsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0">
      {isTimestamp(key) ? (
        item[key] && (
          <ToolTip
            content={<div>{millisecondsToDate(item[key])}</div>}
            title={formatDateUi(item[key])}
            isTextTooltip
            placement="top"
          />
        )
      ) : isType(key) ? (
        <div className="bg-light text-center px-4 py-2 rounded">{item[key]}</div>
      ) : isDate(key) ? (
        formatDateUi(item[key])
      ) : key === "status" ? (
        <div
          style={{
            background:
              (item[key] === "complete" && "green") ||
              (item[key] === "in_progress" && "#f00") ||
              (item[key] === "not_started" && "orange"),
            width: 14,
            height: 14,
            borderRadius: "50%",
            margin: "0 auto",
          }}
        ></div>
      ) : hasLongContentRow(key) ? (
        formatLongRow(item[key])
      ) : key === "base_unit_total" ? (
        formatLongRow(item[key], 4)
      ) : (
        item[key]
      )}
    </td>
  ));
}

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index + 1),
];

export const getHarvests = (
  request,
  dispatch,
  setStoreData,
  setLocalData,
  setLoading,
  harvests,
  users
) => {
  request(() => harvests()).then((data) => {
    const harvestsArr =
      data &&
      data.map(({ supervisor, product, farm, end_time, start_time, ...harvest }) => {
        return {
          ...harvest,
          supervisor: [supervisor.firstname, supervisor.lastname].join(" "),
          product: product.name,
          farm: farm.name,
          start_time: setUtcDate(start_time),
          end_time: setUtcDate(end_time),
          duration: end_time ? timeDiffCalc(end_time, start_time) : "",
          status:
            (end_time && "complete") ||
            (start_time && !end_time && "in_progress") ||
            (!start_time && "not_started"),
        };
      });
    dispatch(setStoreData(harvestsArr));
    setLocalData(harvestsArr);
    setLoading(false);
  });
};

export const millisecondsToDate = (date) => {
  const myDate = new Date(date);
  return (
    myDate.getHours() +
    ":" +
    ("0" + myDate.getMinutes()).slice(-2) +
    ":" +
    ("0" + myDate.getSeconds()).slice(-2)
  );
};

export const setUtcDate = (date) => {
  if (!date) return;
  const d = new Date(date);
  d.setUTCFullYear(d.getFullYear(), d.getMonth(), d.getDate());
  d.setUTCHours(d.getHours(), d.getMinutes(), d.getSeconds());
  return d;
};

export const formatDateUi = (date) => {
  return date ? format(new Date(date), "dd/MM/yyyy") : "";
};

export const formatDate = (date, shape, dateFormat = "yyyy-MM-dd") => {
  return shape === "date"
    ? format(new Date(date), dateFormat)
    : new Date(date).toISOString().slice(0, 19);
};

export const validCheckDateFormat = (date, shape) => {
  if (isValidDate(date)) {
    return shape === "date"
      ? format(new Date(date), "yyyy-MM-dd")
      : new Date(date).toISOString().slice(0, 19);
  }
};

export const setSelectOptions = ({ name: label, id: value }) => ({
  value,
  label,
  key: label,
});

export const getDuration = (data) => data.map((item) => item.duration).filter(Boolean);

export const getDurationTotal = (data) => data.map((item) => item.duration_total).filter(Boolean);

export const getRate = (data) => data.map((item) => item.rate).filter(Boolean);

export const getTotalLunchTime = (data) => data.map((item) => item.lunch_break).filter(Boolean);

export const getDurationForMonth = (data) => {
  const currentMonth = new Date().getMonth();
  return data
    .filter((item) => {
      const itemDate = new Date(item.date).getMonth();
      return itemDate === currentMonth;
    })
    .map((item) => item.duration)
    .filter(Boolean);
};

export function formatFileName(name) {
  return name
    .split("-")
    .slice(1)
    .join("-");
}

export const handleInactiveData = (dispatch, data, setState, setLoader) => {
  if (!data) return;
  dispatch(setState(data));
  setLoader(false);
};

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function sortByDate(a, b) {
  return new Date(a.date) - new Date(b.date);
}

export function timeDiffCalc(endDate, startDate, lunch) {
  const dateFuture = new Date(endDate);
  const dateNow = new Date(startDate);
  let diffInMilliSeconds;

  const halfHourinSeconds = 30 * 60;

  diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  if (lunch) {
    diffInMilliSeconds =
      diffInMilliSeconds > halfHourinSeconds ? diffInMilliSeconds - 30 * 60 : diffInMilliSeconds;
  }

  // calculate days
  // const days = Math.floor(diffInMilliSeconds / 86400);
  // diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600);
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  const seconds = diffInMilliSeconds % 60;

  let difference = "";
  // if (days > 0) {
  //   difference += days === 1 ? `${days} day, ` : `${days} days, `;
  // }

  difference += `${hours}:`;

  difference += minutes < 10 ? `0${minutes}:` : `${minutes}:`;

  difference += seconds < 10 ? `0${seconds}` : seconds;

  return difference;
}

export function timeDiffCalcInMili(endDate, startDate, lunch) {
  const dateFuture = new Date(endDate);
  const dateNow = new Date(startDate);
  let diffInMilliSeconds;

  const halfHourinSeconds = 30 * 60;

  diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  if (lunch) {
    diffInMilliSeconds =
      diffInMilliSeconds > halfHourinSeconds ? diffInMilliSeconds - 30 * 60 : diffInMilliSeconds;
  }
  return diffInMilliSeconds;
}

export function timeDiffDisplayInMili(diffInMilliSeconds) {
  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600);
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  // if (days > 0) {
  //   difference += days === 1 ? `${days} day, ` : `${days} days, `;
  // }

  difference += `${hours}:`;

  difference += minutes < 10 ? `0${minutes}` : minutes;

  return difference;
}

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (!obj[key] && typeof obj[key] !== "boolean") delete obj[key];
  });
};

export const accessCheck = (user) =>
  user.role === "admin" || user.role === "manager" || user.is_superuser;

export const filterSearch = (search, data, headings) => {
  return data.filter((item) => {
    const _search = Object.entries(search).map((item) => [
      headings.find((i) => i[1] === item[0])[0],
      item[1],
    ]);
    return (
      _search.length === 0 ||
      _search.every(([k, v]) => {
        if (Array.isArray(item[k])) {
          return item[k].includes(v);
        }
        return item[k] === v;
      })
    );
  });
};

export const formatLongRow = (formatedValue = "", allowedLength = 11) => {
  let content = formatedValue;
  if (content === null) return content;
  if (typeof content === "number") content = String(content);
  if (content.length > allowedLength) {
    return (
      <ToolTip
        content={<div>{content.slice(0, allowedLength) + "..."}</div>}
        title={content}
        isTextTooltip
        placement="top"
      />
    );
  } else return content;
};

export const handleSort = (data = [], key) => {
  data.sort(function(a, b) {
    if (a[key] > b[key]) return -1;
    if (a[key] < b[key]) return 1;
    return 0;
  });
};

export const sortHelper = (a, b) => {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};


export const removeEmptyFields = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") delete obj[key];
  });
};
