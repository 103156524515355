import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputIcon from "@material-ui/icons/Input";
import DataGrid from "../../../ui/components/DataGrid";
import Tabs from "../../../ui/components/Tabs";
import { downloadFile } from "../AWS-S3";
import { FilterForm } from "../../../ui/structures/FilterForm";
import { getAllAttendances, exportAllAttendances } from "../TeamAttendance/_api";
import {
  renderAttendanceReportsRow,
  setSelectOptions,
  validCheckDateFormat,
} from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { getAllFarms } from "../Farms/_api";
import { getAllUsers } from "../UserManagment/_api";
import { getAllEmployees } from "../EmployeeManagement/_api";
import {
  setAttendanceReports,
  setAttendanceSummeryReports,
  setFilterOptions,
  resetFilterOptions,
} from "./_redux/actions";
import { modifyAttendnaces, calculateSummaryAttendance } from "./helpers";
import { filterResetValues } from "./constants";
import ToolTip from "../../../ui/components/ToolTip";
import { Loader } from "../../../ui/components/Loader";
import { WarningAlertBar } from "../../../ui/components/AlertBar";

const HEADINGS = [
  ["start_date", "Start Date"],
  ["end_date", "End Date"],
  ["employee_fullname", "Name"],
  ["nickname", "Nickname"],
  ["mobile", "Mobile"],
  ["farm", "Farm"],
  ["start_time", "Start Time"],
  ["end_time", "Finish Time"],
  ["lunch_break", "Lunch Break"],
  ["duration", "Total Hours"],
  ["duration_with_lunch", "Total Hours (exclude breaks)"],
  ["supervisor", "Supervisor"],
];

const SUMMARY_HEADINGS = [
  ["firstname", "First Name"],
  ["lastname", "Last Name"],
  ["nickname", "Nickname"],
  ["lunch_break", "Lunch Break"],
  ["duration_in_mili", "Total Hours"],
  ["duration_with_lunch_in_mili", "Total Hours (exclude breaks)"],
];

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [supervisors, setSupervisors] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [farms, setFarms] = useState([]);
  const [resetKey, setResetKey] = useState(false);

  const data = useSelector(({ attendanceReports: { reportsList } }) => reportsList) || [];
  const summaryData =
    useSelector(({ attendanceReports: { reportsSummeryList } }) => reportsSummeryList) || [];
  const filterOptions = useSelector(({ attendanceReports: { filterOptions } }) => filterOptions);

  function sortByDate(a, b) {
    return new Date(a.start_time) - new Date(b.start_time);
  }

  const getAttendances = (values, actions) => {
    setLoading(true);
    request(() =>
      values
        ? getAllAttendances(
            validCheckDateFormat(values.from, "date"),
            validCheckDateFormat(values.to, "date"),
            values.supervisors,
            values.employees,
            values.farms
          )
        : getAllAttendances()
    )
      .then((data) => data && modifyAttendnaces(data).sort(sortByDate))
      .then((data) => {
        dispatch(setAttendanceReports(data));
        values && dispatch(setFilterOptions(values));
        setLoading(false);
        return data;
      })
      .then((data) => data && calculateSummaryAttendance(data))
      .then((data) => dispatch(setAttendanceSummeryReports(data)))
      .finally(() => actions && actions.setSubmitting(false));
  };

  useEffect(() => {
    if (!data.length) getAttendances(filterOptions);
    Promise.all([request(getAllFarms()), request(getAllEmployees()), request(getAllUsers())]).then(
      ([farms, emmployees, users]) => {
        if (!farms || !emmployees || !users) return;
        setFarms(farms.filter(({ name }) => name));
        setEmployees(
          emmployees
            .filter(({ firstname, lastname }) => firstname || lastname)
            .map(({ firstname, lastname, ...employee }) => ({
              ...employee,
              name: [firstname, lastname].join(" "),
            }))
        );
        setSupervisors(
          users
            .filter(({ firstname, lastname }) => firstname || lastname)
            .map(({ firstname, lastname, ...user }) => ({
              ...user,
              name: [firstname, lastname].join(" "),
            }))
        );
      }
    );
    // eslint-disable-next-line
  }, []);

  const onReset = (handleReset) => () => {
    const threeMonthInMs = 7889400000;
    const filterPayload = { ...filterResetValues, from: new Date(Date.now() - threeMonthInMs) };
    handleReset({ values: filterPayload });
    getAttendances(filterPayload);
    dispatch(resetFilterOptions());
    setResetKey(new Date(Date.now()));
  };

  const handleDownload = () => {
    setExportLoading(true);
    downloadFile(
      "",
      exportAllAttendances,
      setExportLoading,
      validCheckDateFormat(filterOptions.from, "date"),
      validCheckDateFormat(filterOptions.to, "date"),
      filterOptions.supervisors,
      filterOptions.employees,
      filterOptions.farms
    );
  };

  const sortHelper = function(a, b) {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  };

  const farmsOptions = useMemo(() => farms.map(setSelectOptions).sort(sortHelper), [farms]);

  const employeeOptions = useMemo(() => employees.map(setSelectOptions).sort(sortHelper), [
    employees,
  ]);

  const supervisorOptions = useMemo(() => supervisors.map(setSelectOptions).sort(sortHelper), [
    supervisors,
  ]);

  return (
    <>
      <WarningAlertBar message="Due to a large amount of data reports can be filtered only within 3 month range" />
      <Loader isOpen={loading} />
      <div className="row justify-content-center">
        <div className="col-12">
          <div>
            <FilterForm
              onSubmit={getAttendances}
              onReset={onReset}
              supervisors={supervisorOptions}
              employees={employeeOptions}
              farms={farmsOptions}
              filterOptions={filterOptions}
              title="Filter Attendance Reports"
              key={resetKey}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between">
              <h3 className="text-dark">Attendance Reports</h3>

              <div>
                <ToolTip
                  icon={<InputIcon fontSize="large" />}
                  title="Export Attendances Data to CSV"
                  handleClick={handleDownload}
                  loading={exportLoading}
                />
              </div>
            </div>

            <Tabs
              defaultActive="0"
              tabs={[
                {
                  label: "Detailed Report",
                  icon: "",
                  tabPanel: (
                    <DataGrid
                      data={data}
                      headings={HEADINGS}
                      renderRow={renderAttendanceReportsRow}
                      loading={loading}
                      dataPerPage="100"
                      totalAttendances
                      editable
                      edited
                      link="attendance-reports"
                    />
                  ),
                },
                {
                  label: "Summary Report",
                  icon: "",
                  tabPanel: (
                    <DataGrid
                      data={summaryData}
                      headings={SUMMARY_HEADINGS}
                      renderRow={renderAttendanceReportsRow}
                      loading={loading}
                      totalAttendances
                      dataPerPage="100"
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
