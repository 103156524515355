import React, { useState, useEffect, useMemo } from "react";
import { TextField } from "@material-ui/core";
import get from "lodash/get";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { formatDate, setUtcDate } from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { getAllEmployees } from "../EmployeeManagement/_api";
import { createMyAttendance } from "./_api";

export const AddAttendance = ({ setData, data = [], farm, disabled }) => {
  const { request } = useFetch();

  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [key, setKey] = useState(false);

  const arrayOfIds = useMemo(() => data.map(({ id }) => id), [data]);

  const filteredEmployees = useMemo(() => employees.filter(({ id }) => !arrayOfIds.includes(id)), [
    arrayOfIds,
    employees,
  ]);

  useEffect(() => {
    request(getAllEmployees()).then((employees) => employees && setEmployees(employees));
    // eslint-disable-next-line
  }, []);

  const handleChange = (_, value) => {
    setSelectedEmployee(value);
  };

  const findEmployee = () => data.find(({ id }) => id === selectedEmployee.id);

  const handleAdd = () => {
    if (!Object.keys(selectedEmployee).length && findEmployee()) return;
    request(createMyAttendance, {
      start_time: formatDate(Date.now()),
      date: formatDate(Date.now(), "date"),
      employee_id: selectedEmployee.id,
      end_time: null,
      has_lunch: false,
      farm_id: get(farm, "id")
    }).then((res) => {
      res &&
        setData([
          ...data,
          {
            ...res,
            start_time: setUtcDate(res.start_time),
            end_time: setUtcDate(res.end_time),
            firstname: selectedEmployee.firstname,
            lastname: selectedEmployee.lastname,
            nickname: selectedEmployee.nickname,
            mobile: selectedEmployee.mobile,
            id: selectedEmployee.id,
            attendance_id: res.id,
            record: true,
          },
        ]);
      setKey(!key);
    });
  };

  return (
    <div className="d-flex align-items-center w-75">
      <div className="col-4 mr-5">
        <Autocomplete
          options={filteredEmployees}
          id="3"
          getOptionLabel={({ firstname, lastname }) => [firstname, lastname].join(" ")}
          key={key}
          renderInput={(params) => (
            <TextField {...params} label="Find employee" variant="outlined" color="secondary" />
          )}
          onChange={handleChange}
        />
      </div>
      <button className="btn btn-primary" onClick={handleAdd} disabled={disabled}>
        Start attendance
      </button>
    </div>
  );
};
