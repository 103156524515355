import React from "react";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Tabs from "../../../ui/components/Tabs";
import { MappedInput } from "../../../ui/components/Input";
import { MappedSelect } from "../../../ui/components/Select";
import { userRoles, initialValues } from "./constants";

export const UserForm = ({ data, handleClose, handleSubmit, users }) => {

  const findDuplicate = (value) => {
    return users.find((email) => email === value && email !== data.email);
  };

  return (
    <Formik
    initialValues={Object.keys(data).length ? data : initialValues}
      validationSchema={Yup.object({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        mobile: Yup.string()
          .required()
          .min(10, "Must be at least 10 symbols")
          .matches(/(^\+{1})/, "Must start with country code")
          .matches(/(^\+{1}[0-9]+$)/, "Must contain only numbers"),
        email: Yup.string()
          .email()
          .required()
          .test(
            "Email duplicate",
            "Email Address already exists",
            (value) => value !== findDuplicate(value)
          ),
      })}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="d-flex justify-content-between mb-3">
          <div className="w-50 mr-3">
            <div className="bg-white rounded">
              <div className="px-15 py-10">
                <h6 className="mb-10">User Details</h6>
                <div className="mb-10 text-center">Account information</div>

                <div className="row mb-3">
                  <div className="col-3">
                    <p className="text-right text-muted">First Name</p>
                  </div>
                  <div className="col-9 mr-auto">
                    <MappedInput
                      name="firstname"
                      wrapperClassName="w-100"
                      data-testid="firstname"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3">
                    <p className="text-right text-muted">Last Name</p>
                  </div>
                  <div className="col-9 mr-auto">
                    <MappedInput name="lastname" wrapperClassName="w-100" data-testid="lastname" />
                  </div>
                </div>

                <div className="my-10 text-center">Contact information</div>

                <div className="row mb-3">
                  <div className="col-3">
                    <p className="text-right text-muted">Mobile</p>
                  </div>
                  <div className="col-9 mr-auto">
                    <MappedInput
                      name="mobile"
                      icon="phone"
                      wrapperClassName="w-100"
                      data-testid="mobile"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-3 ">
                    <p className="text-right text-muted">Email Address</p>
                  </div>
                  <div className="col-9 mr-auto">
                    <MappedInput
                      name="email"
                      icon="alternate_email"
                      wrapperClassName="w-100"
                      data-testid="email"
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center mt-10">
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className="px-9 mr-3"
                    onClick={handleClose}
                    data-testid="cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className="px-9"
                    disabled={isSubmitting}
                    data-testid="submit"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50 ml-3">
            <div className="d-flex flex-column rounded h-100">
              <Tabs
                defaultActive="0"
                tabs={[
                  {
                    label: "Butler",
                    icon: "",
                    tabPanel: (
                      <div className="px-15 py-10">
                        <h6>Roles</h6>
                        <div className="col-6 mt-10 mb-20">
                          <MappedSelect options={userRoles} name="role" data-testid="role" />
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
