import { emoloyeesTypes } from "./actions";

const initialState = {
  employeesList: [],
};

export function employeesReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case emoloyeesTypes.SET_DATA:
      return {
        ...state,
        employeesList: payload,
      };

    case emoloyeesTypes.DELETE_EMPLOYEE:
      return {
        ...state,
        employeesList: [...state.employeesList.filter(({ id }) => id !== payload)],
      };

    default:
      return state;
  }
}
