import * as Yup from "yup";

export const harvestConfirmation = Yup.object({
  employees_amount: Yup.number()
    .typeError("Must be a Number")
    .required()
    .max(50, "Must be less than or equal to 50"),
  product_qty: Yup.number()
    .typeError("Must be a Number")
    .required(),
});

export const harvestCardSchema = (maxQty) =>
  Yup.object({
    product_type: Yup.string().required("Product Type is required"),
    date: Yup.string().required("Date is required"),
    product_id: Yup.string().required("Product is required"),
    farm_id: Yup.string().required("Farm is required"),
    product_qty: Yup.number()
      .typeError("Must be a Number")
      .required("Product quantity is required")
      .max(maxQty, `Must be less than or equal to ${maxQty}`),
    employees_amount: Yup.number()
      .typeError("Must be a Number")
      .max(50, "Must be less than or equal to 50"),
  });

export const plannedHarvestSchema = (maxQty) =>
  Yup.object({
    product_type: Yup.string().required("Product Type is required"),
    date: Yup.string().required("Date is required"),
    product_id: Yup.string().required("Product is required"),
    farm_id: Yup.string().required("Farm is required"),
    supervisor_id: Yup.string().required("Supervisor is required"),
    product_qty: Yup.number()
      .typeError("Must be a Number")
      .required("Product quantity is required")
      .integer("Must be an Integer")
      .max(maxQty, `Must be less than or equal to ${maxQty}`),
    employees_amount: Yup.number()
      .typeError("Must be a Number")
      .max(50, "Must be less than or equal to 50")
      .integer("Must be an Integer")
      .min(1, "Must be more than or equal to 1"),
  });

export const productsCardSchema = Yup.object({
  name: Yup.string().required("Name required"),
  conversion_factor: Yup.string(),
  bundles_type_allowed: Yup.boolean(),
  bunch_type_allowed: Yup.boolean(),
  loose_type_allowed: Yup.boolean(),
  loose_type_conversion_factor: Yup.number("Must be number"),
});

export const userProfileSchema = Yup.object({
  firstname: Yup.string()
    .required()
    .matches(/(^[a-zA-Z ]+$)/, "Only letters are allowed"),
  lastname: Yup.string()
    .required()
    .matches(/(^[a-zA-Z]+$)/, "Only letters are allowed"),
  email: Yup.string()
    .email()
    .required()
    .matches(/(^[a-z0-9@.]+$)/, "Only lowercase characters are allowed"),
  mobile: Yup.string().matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
});

export const AttendanceFormSchema = Yup.object({
  employee_id: Yup.string().required(),
  farm_id: Yup.string().required(),
  date: Yup.string().required(),
  note: Yup.string().required(),
});
