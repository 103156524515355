import React, { useState, useEffect, useMemo } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import { useFetch } from "../../../hooks/fetch.hook";
import { getAttendanceById } from "../TeamAttendance/_api";
import { AttendanceForm } from "./AttendanceForm";
import { getAllFarms } from "../Farms/_api";
import { formatDate, setSelectOptions } from "../../../ui/helpers/helpers";
import { getAllEmployees } from "../EmployeeManagement/_api";
import { modifyAttendance } from "../TeamAttendance/_api";
import { getAttendance } from "./_redux/selectors";
import { updateAttendaceReport } from "./_redux/actions";
import { modifyAttendanceObject } from "./helpers";

export const Card = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [cardData, setCardData] = useState({});
  const [farms, setFarms] = useState([]);
  const [employees, setEmployees] = useState([]);

  const data = useSelector(getAttendance(id)) || {};
  const reportsList = useSelector(
    ({ attendanceReports: { reportsList } }) => reportsList
  );

  useEffect(() => {
    Promise.all([request(getAllFarms()), request(getAllEmployees(false))]).then(
      ([farms, employees]) => {
        if (!farms || !employees) return;
        setFarms(farms.filter(({ name }) => name));
        setEmployees(
          employees.filter(({ firstname, lastname }) => firstname || lastname)
        );
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!Object.keys(data).length) {
      request(getAttendanceById, id).then(
        (attendance) =>
          attendance && setCardData(modifyAttendanceObject(attendance))
      );
    } else {
      setCardData({
        ...data,
        start_time: data.start_time && new Date(data.start_time),
        end_time: data.end_time && new Date(data.end_time),
        date: data.date && new Date(data.date),
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleClose = () => history.push("/attendance/attendance-reports");

  const handleSubmit = (values, { setSubmitting }) => {
    request(
      modifyAttendance,
      {
        ...values,
        date: formatDate(values.date, "date"),
        start_time: formatDate(values.start_time),
        end_time: formatDate(values.end_time),
      },
      id
    )
      .then((data) => {
        if (!data) return;
        reportsList.length &&
          dispatch(updateAttendaceReport(modifyAttendanceObject(data)));
        history.push("/attendance/attendance-reports");
      })
      .finally(() => setSubmitting(false));
  };

  const farmsOptions = useMemo(() => farms.map(setSelectOptions), [farms]);

  const employeesOptions = useMemo(
    () =>
      employees.map(({ firstname, lastname, id }) => ({
        value: id,
        label: [firstname, lastname].join(" "),
        key: id,
      })),
    [employees]
  );

  if (!Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }
  return (
    <AttendanceForm
      data={cardData}
      farms={farmsOptions}
      employees={employeesOptions}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};
