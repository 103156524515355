import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "../../../ui/components/Input";

export const ProductQty = ({ products = [], setMaxQty }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (!values.product_type) return;
    const productMaxQty = (products.find(
      (product) => String(product.id) === String(values.product_id)
    ) || {})[values.product_type.toLowerCase() + "_type_maximum_limit"];
    if (!productMaxQty) {
        setMaxQty(10000000)
        return;
    }
    setMaxQty(productMaxQty);
    // eslint-disable-next-line
  }, [values.product_type, values.product_id]);

  return (
    <>
      <div className="col-2">
        <p className="text-right text-muted">Quantity</p>
      </div>
      <div className="col-3">
        <MappedInput name="product_qty" wrapperClassName="w-100" data-testid="product_qty" />
      </div>
    </>
  );
};
