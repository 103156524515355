import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { Formik } from "formik";
import { useFetch } from "../../../hooks/fetch.hook";
import { harvestConfirmation } from "../../../helpers/schema";
import { MappedInput } from "../../../ui/components/Input";
import { removeEmpty, formatDate } from "../../../ui/helpers/helpers";
import { createHarvest, modifyHarvest } from "./_api";
import { FormActions } from "../../../ui/structures/FormActions";

const useStyles = makeStyles({
  root: { backgroundColor: "rgba(0,0,0,0.5)" },
});

export function Confirmation({ setOpen, isOpen, data, startTime, endTime, harvestId, isAdmin }) {
  const classes = useStyles();

  const { request } = useFetch();
  const history = useHistory();

  const { product_qty, employees_amount } = data || {};

  const initialValues = {
    employees_amount: employees_amount || "",
    product_qty: product_qty || "",
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    removeEmpty(data);
    request(
      modifyHarvest,
      {
        ...data,
        ...values,
        date: formatDate(data.date, "date"),
        start_time: startTime,
        end_time: formatDate(Date.now()),
      },
      harvestId,
      isAdmin
    )
      .then(
        (res) =>
          res &&
          request(createHarvest, {
            ...res,
            date: formatDate(data.date, "date"),
            product_qty: product_qty - res.product_qty,
            employees_amount: employees_amount - res.employees_amount,
            start_time: null,
            end_time: null,
          })
      )
      .then((data) => data && history.push(`/operations/current-harvests`))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={harvestConfirmation}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog classes={classes} onClose={handleClose} open={isOpen}>
          <DialogTitle>Submit Total Qty Harvested and New number of staff</DialogTitle>
          <DialogContent>
            <div className="row mb-3">
              <div className="col-3">
                <p className="text-right text-muted">Quantity</p>
              </div>
              <div className="col-8 mr-auto">
                <MappedInput name="product_qty" wrapperClassName="w-100" required />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-3">
                <p className="text-right text-muted">Number Of People</p>
              </div>
              <div className="col-8 mr-auto">
                <MappedInput name="employees_amount" wrapperClassName="w-100" required />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <FormActions
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
