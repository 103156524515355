import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { CustomDatePicker } from "../../../../ui/components/DatePicker";

export const LabelDate = ({ handleUpdateField, data = {} }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!values.apply_date) {
      handleUpdateField("label_date", "", setFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.apply_date]);
  return (
    <div>
      <CustomDatePicker
        name="label_date"
        shouldCloseOnSelect
        selected={data.label_date}
        disabled={!values.apply_date}
        onChange={(data) => {
          handleUpdateField("label_date", new Date(data), setFieldValue);
        }}
      />
    </div>
  );
};
