export const SEARCH_DATA = [["name", "Product"]];

export const initialValues = {
  name: "",
  bunch_type_allowed: false,
  loose_type_allowed: false,
  bundles_type_allowed: false,
  loose_kg_type_allowed: false,
  crates_type_allowed: false,
  bin_type_allowed: false,
};

export const productTypes = [
  {
    name: "bunch_type_allowed",
    label: "Bunch"
  },
  {
    name: "loose_type_allowed",
    label: "Loose"
  },
  {
    name: "bundles_type_allowed",
    label: "Bundles"
  },
  {
    name: "loose_kg_type_allowed",
    label: "Loose (kg)"
  },
  {
    name: "crates_type_allowed",
    label: "Crates"
  },
  {
    name: "bin_type_allowed",
    label: "Bin"
  },
]
