import get from "lodash/get";
import {
  setUtcDate,
  timeDiffCalc,
  formatDate,
} from "../../../ui/helpers/helpers";

export const modifyHarvests = (data) => {
  return data.map((harvest) => modifyHarvestObject(harvest));
};

function timeConvert(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

const getDistanceinHours = (startDate, endDate) => {
  const diffInSeconds = (new Date(endDate) - new Date(startDate)) / 1000;
  const hours = diffInSeconds / 3600;
  return +hours;
};

export const modifyHarvestObject = (data) => {
  const conversion_rate =
    get(data, "product." + data.product_type + "_type_conversion_factor") || 1;
  const duration_total =
    +data.employees_amount * getDistanceinHours(data.start_time, data.end_time);

  return {
    ...data,
    supervisor: [
      get(data, "supervisor.firstname"),
      get(data, "supervisor.lastname"),
    ].join(" "),
    product: get(data, "product.name"),
    product_type_conversion_rate: conversion_rate,
    farm: get(data, "farm.name"),
    start_time: data && setUtcDate(data.start_time),
    end_time: data && setUtcDate(data.end_time),
    date: data.date && new Date(data.date),
    label_date: data.label_date && new Date(data.label_date),
    duration: data.end_time
      ? timeDiffCalc(get(data, "end_time"), get(data, "start_time"))
      : "",
    duration_total: data.end_time ? +duration_total.toFixed(2) : "",
    duration_total_to_ui: data.end_time
      ? timeConvert(duration_total * 3600)
      : "",
    status:
      (data.end_time && "complete") ||
      (data.start_time && !data.end_time && "in_progress") ||
      (!data.start_time && "not_started"),
    rate: data.end_time
      ? +(
          (data.product_qty * conversion_rate) /
          (data.employees_amount *
            (getDistanceinHours(data.start_time, data.end_time) === 0
              ? 1
              : getDistanceinHours(data.start_time, data.end_time)))
        ).toFixed(0)
      : "",
    base_unit_total: data.product_qty * conversion_rate,
  };
};

export const calculateTotal = (data, option) => {
  const count = data
    .filter((item) => item.product_type === option)
    .reduce((acc, value) => acc + value.product_qty, 0);
  return count === 0 ? "None" : count.toFixed(2);
};

export const baseUnitTotalQuantity = (data) => {
  const count = data.reduce((acc, value) => acc + value.base_unit_total, 0);
  return count === 0 ? "None" : Number(count).toFixed(2);
};
