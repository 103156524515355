import config from "react-global-configuration";

export const getHarvestList = (
  start = "",
  end = "",
  product_id = [],
  farm_id = [],
  type = [],
  supervisors = [],
  status = []
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/harvests?${start && "&end_after=" + start}${
      end ? "&start_before=" + end : ""
    }${product_id.length ? product_id.map((item) => "&product_id=" + item).join("") : ""}${
      farm_id.length ? farm_id.map((item) => "&farm_id=" + item).join("") : ""
    }${type.length ? type.map((item) => "&product_type=" + item).join("") : ""}${
      supervisors.length ? supervisors.map((item) => "&supervisor_id=" + item).join("") : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getCurrenttHarvests = (
  start = "",
  end = "",
  product_id = [],
  farm_id = [],
  type = [],
  supervisors = [],
  status = []
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/me/harvests?${start && "&end_after=" + start}${
      end ? "&start_before=" + end : ""
    }${product_id.length ? product_id.map((item) => "&product_id=" + item).join("") : ""}${
      farm_id.length ? farm_id.map((item) => "&farm_id=" + item).join("") : ""
    }${type.length ? type.map((item) => "&product_type=" + item).join("") : ""}${
      supervisors.length ? supervisors.map((item) => "&supervisor_id=" + item).join("") : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getHarvestById = (harvestId, isAdmin) =>
  fetch(`${config.get("ApiUrl").Rest}/${isAdmin ? "harvests" : "users/me/harvests"}/${harvestId}`, {
    mode: "cors",
    credentials: "include",
  });

export const createHarvest = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/users/me/harvests`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteHarvest = (harvestId) =>
  fetch(`${config.get("ApiUrl").Rest}/harvests/${harvestId}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const createPlannedHarvest = (data, isAdmin) =>
  fetch(`${config.get("ApiUrl").Rest}/${isAdmin ? "harvests" : "users/me/harvests"}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const modifyHarvest = (harvestData, harvestId, isAdmin) =>
  fetch(`${config.get("ApiUrl").Rest}/${isAdmin ? "harvests" : "users/me/harvests"}/${harvestId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(harvestData),
    mode: "cors",
    credentials: "include",
  });

export const exportAllHarvests = (fileName, props) =>
  fetch(
    `${config.get("ApiUrl").Rest}/harvests/export?${props[0] &&
      "&end_after=" + props[0]}${props[1] && "&start_before=" + props[1]}${
      props[2].length ? props[2].map((item) => "&product_id=" + item).join("") : ""
    }${props[3].length ? props[3].map((item) => "&farm_id=" + item).join("") : ""}${
      props[4].length ? props[4].map((item) => "&product_type=" + item).join("") : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
