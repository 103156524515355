import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { makeStyles, Checkbox } from "@material-ui/core";
import get from "lodash/get";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { uniqBy } from "lodash";

const useStyles = makeStyles({
  tag: {
    backgroundColor: "#aee2f2",
    color: "black",
  },
  root: {
    zIndex: 0,
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const Autocomplete = ({
  options = [],
  tip,
  placeholder = "Select option",
  loading,
  setValue,
  name,
  defaultValue,
  defaultValueField = "id",
  disabled,
  label = "label",
  disableCloseOnSelect = true,
  variant = "outlined",
  multiple = false,
  withCheckbox = true,
  ...other
}) => {
  const classes = useStyles();

  const sortHelper = function(a, b) {
    if (a[label] > b[label]) return 1;
    if (a[label] < b[label]) return -1;
    return 0;
  };

  const handleChange = (_, value) => {
    setValue(name, get(value, "value", ""));
  };

  const handleChangeMultiple = (_, value = []) => {
    setValue(
      name,
      value.map(({ value }) => value)
    );
  };

  const setDefaultValue = () => options.find((item) => item[defaultValueField] === defaultValue);

  const setMultipleDefaultValue = () =>
    options.filter((item) => (defaultValue || []).includes(item[defaultValueField]));

  useEffect(() => {
    if (!defaultValue || !setValue) return;
    setValue(
      name,
      multiple ? (setMultipleDefaultValue() || []).map(({ value }) => value) : setDefaultValue()
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <MuiAutocomplete
        options={uniqBy(options, label).sort(sortHelper)}
        id={name}
        onChange={multiple ? handleChangeMultiple : handleChange}
        getOptionLabel={(item) => item[label]}
        defaultValue={multiple ? setMultipleDefaultValue : setDefaultValue}
        disabled={disabled}
        multiple={multiple}
        disableCloseOnSelect={disableCloseOnSelect}
        classes={classes}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            color="secondary"
            variant={variant}
            className={classes.root}
          />
        )}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {withCheckbox ? (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            ) : null}
            {option[label]}
          </React.Fragment>
        )}
        {...other}
      />
      {tip && <div>{tip}</div>}
    </div>
  );
};
