import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "@material-ui/core";
import { productsCardSchema } from "../../../helpers/schema";
import { MappedCheckbox } from "../../../ui/components/Checkbox";
import { MappedInput } from "../../../ui/components/Input";
import { ModalInfo } from "../../../ui/components/ModalInfo";
import { initialValues, productTypes } from "./constants";

export const ProductForm = ({ data, handleSubmit, handleClose }) => {
  const [openModal, setOpenModal] = useState(false);
  const [notValidFormat, setNotValidFormat] = useState([]);

  const addNotValidFormat = (format) => {
    if (!notValidFormat.includes(format)) {
      setNotValidFormat((prev) => [...prev, format]);
    }
  };
  const deleteNotValidFormat = (format) => {
    if (notValidFormat.includes(format)) {
      setNotValidFormat((prev) => prev.filter((item) => item !== format));
    }
  };

  const validate = (values) => {
    const errors = {};
    console.log(notValidFormat);

    if (
      values.bin_type_conversion_factor > 700 ||
      values.bin_type_conversion_factor < 100
    ) {
      if (values.bin_type_allowed) {
        errors.bin_type_conversion_factor = "Should be between 100 and 700";
        addNotValidFormat("bin");
      }
    } else if (
      values.loose_kg_type_conversion_factor <= 700 &&
      values.loose_kg_type_conversion_factor >= 100
    ) {
      deleteNotValidFormat("bin");
    }

    if (values.bunch_type_conversion_factor !== 1) {
      if (values.bunch_type_allowed) {
        errors.bunch_type_conversion_factor = "Should be 1";
        addNotValidFormat("bunch");
      }
    } else if (values.bunch_type_conversion_factor === 1) {
      deleteNotValidFormat("bunch");
    }

    if (values.bundles_type_conversion_factor !== 5) {
      if (values.bundles_type_allowed) {
        errors.bundles_type_conversion_factor = "Should be 5";
      }
    } else if (values.bundles_type_conversion_factor === 5) {
      deleteNotValidFormat("bundles");
      addNotValidFormat("bundles");
    }

    if (
      values.crates_type_conversion_factor > 30 ||
      values.crates_type_conversion_factor < 1
    ) {
      if (values.crates_type_allowed) {
        errors.crates_type_conversion_factor = "Should be between 1 and 30";
        addNotValidFormat("crates", values.crates_type_allowed);
      }
    } else if (
      values.crates_type_conversion_factor <= 30 &&
      values.crates_type_conversion_factor >= 1
    ) {
      deleteNotValidFormat("crates");
    }

    if (
      values.loose_kg_type_conversion_factor > 30 ||
      values.loose_kg_type_conversion_factor < 1
    ) {
      if (values.loose_kg_type_allowed) {
        errors.loose_kg_type_allowed = "Should be between 1 and 30";
        addNotValidFormat("loose (kg)", values.loose_kg_type_allowed);
      }
    } else if (
      values.loose_kg_type_conversion_factor <= 30 &&
      values.loose_kg_type_conversion_factor >= 1
    ) {
      deleteNotValidFormat("loose (kg)");
    }

    if (
      values.loose_type_conversion_factor > 30 ||
      values.loose_type_conversion_factor < 1
    ) {
      if (values.loose_type_allowed) {
        errors.loose_type_conversion_factor = "Should be between 1 and 30";
        addNotValidFormat("loose", values.loose_type_allowed);
      }
    } else if (
      values.loose_type_conversion_factor <= 30 &&
      values.loose_type_conversion_factor >= 1
    ) {
      deleteNotValidFormat("loose");
    }

    return errors;
  };

  const onSubmitWithError = (values, isModal) => {
    console.log(values);
    notValidFormat.length
      ? isModal
        ? handleSubmit(values)
        : setOpenModal(true)
      : handleSubmit(values);
  };

  return (
    <Formik
      initialValues={Object.keys(data).length ? data : initialValues}
      validationSchema={productsCardSchema}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ values, handleSubmit, handleReset, isSubmitting, isValidating }) => (
        <Form onSubmit={handleSubmit}>
          <ModalInfo
            isOpen={openModal}
            // isOpen
            setIsOpen={setOpenModal}
            onClose={setOpenModal}
            handleSubmit={onSubmitWithError}
            values={values}
            message={`This is outside the normal range of conversion factors for ${notValidFormat.join(
              ", "
            )}. Are you sure you would like to proceed with setting this conversion factor?`}
          />
          <h6>Product Settings</h6>
          <div className="row">
            <div className="col-12 col-lg-6 mt-10">
              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="text-muted text-right mb-0">Product Name</p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedInput
                    name="name"
                    wrapperClassName="w-100"
                    placeholder="Name ('Broccoli')"
                    data-testid="name"
                  />
                </div>
              </div>
              <div className="row mb-3 mt-10">
                <div className="col-4 mt-3">
                  <p className="text-muted text-right mb-0">Product format</p>
                </div>
                <div className="row col-8 mr-auto">
                  {productTypes.map(({ name, label }) => (
                    <div className="row col-12 mb-6" key={name}>
                      <div className="col-8 text-center mb-2">{label}</div>
                      <div className="row col-12 align-items-center">
                        <div className="col-2">
                          <MappedCheckbox
                            name={name}
                            // label={label}
                            checked={values[name]}
                            labelPlacement="start"
                            data-testid={label.toLowerCase()}
                            // style={{ minWidth: 80 }}
                            wrapperClassName="m-0"
                          />
                        </div>
                        <div className="col-4">
                          <MappedInput
                            name={name.replace("allowed", "conversion_factor")}
                            wrapperClassName="my-auto"
                            type="number"
                            inputProps={{ step: "0.1" }}
                            style={{ width: 100 }}
                            placeholder={"1.00"}
                            disabled={!values[name]}
                            data-testid={name.replace("allowed", "conversion_factor")}
                          />
                        </div>

                        <div className="col-1 mr-2 ml-4">Max:</div>

                        <div className="col-4">
                          <MappedInput
                            name={name.replace("allowed", "maximum_limit")}
                            wrapperClassName="my-auto"
                            type="number"
                            inputProps={{ step: "0.1" }}
                            style={{ width: 100 }}
                            placeholder={"1.00"}
                            disabled={!values[name]}
                            data-testid={name.replace("allowed", "maximum_limit")}
                          />
                        </div>

                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-12 d-flex justify-content-end align-items-center mt-33">
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
                data-testid="cancel"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                // type="submit"
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
                data-testid="submit"
                // disabled={isSubmitting}
                onClick={() => onSubmitWithError(values)}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
